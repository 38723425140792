import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function OnlineFriend({ user }) {
    const navigate = useNavigate();
    return (
        <div className="col-4 col-md-3 p-1 d-flex justify-content-center align-items-center">
            <img
                src={user.profilePicture}
                alt=""
                title={user.username}
                className="onlinefrienditem rounded-circle"
                onClick={() => navigate(`/${user.username}/${user.id}`)}
            />
        </div>
    );
}
