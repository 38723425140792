import React from 'react'
import { Link } from 'react-router-dom'
import User from '../../../utilities/user/User'

export default function SuggestedForYou({ suggestedUsers }) {
  return (
    <div className="w-100 p-1 bg-white rounded px-2 suggestedforyousection">
      <div className="d-flex justify-content-between align-items-center suggestedforyou">
        <label>Suggested For You</label>
      </div>
      <div className="row px-2 mt-1">
        {
          suggestedUsers.length == 0 ?
            <div className='d-flex justify-content-center align-items-center' style={{ fontSize: "12px", fontStyle: "italic", fontWeight: "100" }}>Loading Users For You...</div>
            :
            suggestedUsers.map(user => (
              <User key={user.id} user={user} />
            ))
        }
      </div>
      <div className="d-flex justify-content-end align-items-center pe-2 pt-1 suggestedforyouseemore">
        <Link to="/detailedsearch" onClick={() => localStorage.setItem("currentLanguage", "")}>
          <label className="text-primary">See More</label>
        </Link>
      </div>
    </div>
  )
}
