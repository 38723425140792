import React from 'react'
import { Link } from 'react-router-dom'
import OnlineFriends from './onlineusers/OnlineFriends'
import Birthdays from './birthdays/Birthdays'
import "./LeftSidebar.css"

export default function LeftSidebar() {
    return (
        <div className="d-flex flex-column justify-content-around align-items-center p-2">
            <div className="w-100 row my-2 bg-white py-2 rounded leftsidebarlistitem">
                <div className="col-5 d-flex justify-content-end align-items-center">
                    <i className="fa-solid fa-house leftsidebarimg"></i>
                </div>
                <div className="col-7 d-flex justify-content-start align-items-center">
                    <Link to="/dashboard" className="text-dark">
                        <label className="leftsidebarlabel">Dashboard</label>
                    </Link>
                </div>
            </div>

            <div className="w-100 row my-2 bg-white py-2 rounded leftsidebarlistitem">
                <div className="col-5 d-flex justify-content-end align-items-center">
                    <i className="fa-solid fa-id-card leftsidebarimg"></i>
                </div>
                <div className="col-7 d-flex justify-content-start align-items-center">
                    <Link to="/editprofile" className="text-dark">
                        <label className="leftsidebarlabel">Edit Profile</label>
                    </Link>
                </div>
            </div>

            <div className="w-100 row my-2 bg-white py-2 rounded leftsidebarlistitem">
                <div className="col-5 d-flex justify-content-end align-items-center">
                    <i className="fa-solid fa-envelope leftsidebarimg"></i>
                </div>
                <div className="col-7 d-flex justify-content-start align-items-center">
                    <Link to="/messages" className="text-dark">
                        <label className="leftsidebarlabel">Messages</label>
                    </Link>
                </div>
            </div>

            <div className="w-100 row my-2 bg-white py-2 rounded leftsidebarlistitem">
                <div className="col-5 d-flex justify-content-end align-items-center">
                    <i className="fa-solid fa-user-group leftsidebarimg"></i>
                </div>
                <div className="col-7 d-flex justify-content-start align-items-center">
                    <Link to="/friends" className="text-dark">
                        <label className="leftsidebarlabel">Friends</label>
                    </Link>
                </div>
            </div>

            <div className="w-100 row my-2 bg-white py-2 rounded leftsidebarlistitem">
                <div className="col-5 d-flex justify-content-end align-items-center">
                    <i className="fa-solid fa-globe leftsidebarimg"></i>
                </div>
                <div className="col-7 d-flex justify-content-start align-items-center">
                    <Link to="/online" className="text-dark">
                        <label className="leftsidebarlabel">Online</label>
                    </Link>
                </div>
            </div>

            <div className="w-100 row my-2 bg-white py-2 rounded leftsidebarlistitem">
                <div className="col-5 d-flex justify-content-end align-items-center">
                    <i className="fa-solid fa-magnifying-glass leftsidebarimg"></i>
                </div>
                <div className="col-7 d-flex justify-content-start align-items-center">
                    <Link to="/detailedsearch" className="text-dark" onClick={() => localStorage.setItem("currentLanguage", "")}>
                        <label className="leftsidebarlabel">Search</label>
                    </Link>
                </div>
            </div>

            <OnlineFriends />

            <Birthdays />

            <div className="w-100 row my-2 bg-white py-2 rounded leftsidebarlistitem">
                <div className="col-5 d-flex justify-content-end align-items-center">
                    <i className="fa-solid fa-right-from-bracket leftsidebarimg"></i>
                </div>
                <div className="col-7 d-flex justify-content-start align-items-center">
                    <Link to="/" className="text-dark" onClick={() => localStorage.removeItem("user")}>
                        <label className="leftsidebarlabel">Log Out</label>
                    </Link>
                </div>
            </div>
        </div>
    )
}
