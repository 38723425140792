import React from 'react'
import "./RightSidebar.css"
import NewMessages from './newmessages/NewMessages'
import FriendshipRequest from './friendshiprequests/FriendshipRequest'

export default function RightSidebar() {
  return (
    <div className="d-flex flex-column justify-content-around align-items-center p-2">
    <FriendshipRequest/>
    <NewMessages/>
  </div>
  )
}
