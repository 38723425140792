import React, { useEffect, useState } from 'react'

export default function PersonelInformations({ user, updateInfos, setMail, setPassword, setBirthday, setIsBirthdayHidden, setAbout, setHobbies, setFavourites }) {
   
    const [newBirthday, setNewBirthday] = useState("");
    useEffect(() => {
        getBirthday(user);
    }, [user]);

    const getBirthday = (user) => {
        const birthday = new Date(user.birthday);
        const formatDate = birthday.getDate() < 10 ? `0${birthday.getDate()}` : birthday.getDate();
        const formatMonth = birthday.getMonth() < 9 ? `0${birthday.getMonth() + 1}` : birthday.getMonth() + 1;
        const formattedDate = [birthday.getFullYear(), formatMonth, formatDate].join('-');
        setNewBirthday(formattedDate)
    }

    const changeDefaultBirthday = (value) => {
        const birthday = new Date(value);
        const formatDate = birthday.getDate() < 10 ? `0${birthday.getDate()}` : birthday.getDate();
        const formatMonth = birthday.getMonth() < 9 ? `0${birthday.getMonth() + 1}` : birthday.getMonth() + 1;
        const formattedDate = [birthday.getFullYear(), formatMonth, formatDate].join('-');
        setNewBirthday(formattedDate)
        setBirthday(value)
    }

    return (
        <div className="w-100 p-1 bg-white rounded px-2 personalsection">
            <div className="d-flex justify-content-between align-items-center personal">
                <label>Personal Informations</label>
            </div>
            <div className="input-group mb-3 mt-3 rounded infoitem">
                <span className="input-group-text">Mail</span>
                <input
                    type="email"
                    className="form-control"
                    placeholder="Mail"
                    aria-label="Mail"
                    defaultValue={user.mail}
                    onChange={(e) => setMail(e.target.value)}
                />
            </div>

            <div className="input-group mb-3 mt-3 rounded infoitem">
                <span className="input-group-text">Password</span>
                <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    aria-label="Password"
                    defaultValue={user.password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>

            <div className="input-group mb-3 mt-3 rounded infoitem">
                <span className="input-group-text">Birthday</span>
                <input
                    type="date"
                    className="form-control"
                    placeholder="Birthday"
                    aria-label="Birthday"
                    defaultValue={newBirthday}
                    onChange={(e) => changeDefaultBirthday(e.target.value)}
                />
            </div>

            <div className="input-group mb-3 mt-3 rounded infoitem">
                <div className="input-group-text">
                    <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        aria-label="Checkbox for following text input"
                        defaultChecked={user.isHiddenBirthday}
                        onClick={(e) => setIsBirthdayHidden(e.target.checked)}
                    />
                </div>
                <span className="form-control input-group-text">
                    Other users can see when it is my birthday
                </span>
            </div>

            <div className="input-group mb-3 mt-3 rounded infoitem">
                <span className="input-group-text">About</span>
                <textarea
                    className="form-control"
                    aria-label="With textarea"
                    rows={5}
                    defaultValue={user.about}
                    onChange={(e) => setAbout(e.target.value)}
                ></textarea>
            </div>

            <div className="input-group mb-3 mt-3 rounded infoitem">
                <span className="input-group-text">Hobbies</span>
                <textarea
                    className="form-control"
                    aria-label="With textarea"
                    rows={5}
                    defaultValue={user.hobbies}
                    onChange={(e) => setHobbies(e.target.value)}
                ></textarea>
            </div>

            <div className="input-group mb-3 mt-3 rounded infoitem">
                <span className="input-group-text">Favourites</span>
                <textarea
                    className="form-control"
                    aria-label="With textarea"
                    rows={5}
                    defaultValue={user.favourites}
                    onChange={(e) => setFavourites(e.target.value)}
                ></textarea>
            </div>

            <div className="d-flex justify-content-end align-items-center pe-2 mb-2">
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{ fontSize: "10px" }}
                    onClick={() => updateInfos()}
                >
                    Save Changes
                </button>
            </div>
        </div>
    )
}
