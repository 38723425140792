import React, { useEffect, useState } from 'react'
import "./Footer.css"
import axios from 'axios';
import url from '../url';
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

export default function Footer() {
  const [friends, setFriends] = useState([]);
  const [openFooterOnlineFriendsModal, setFooterOpenOnlineFriendsModal] = useState(false);
  var currentUser = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();

  useEffect(() => {
    getOnlineFriends();
  }, []);

  const getOnlineFriends = () => {
    axios
      .get(url + `/friendships/online?username=${currentUser.username}`)
      .then((response) => response.data)
      .then((data) => setFriends(data));
  };

  const date = new Date().getFullYear();

  return (
    <>
      <footer className="bg-white fixed-bottom row footer px-2">
        <div className="d-flex justify-content-around align-items-center">
          <div className="d-flex justify-content-center align-items-center pt-1 text-black fw-bold brandFooter">
            {date} &copy; Penpal City
          </div>
          <div className="d-flex justify-content-center align-items-center text-white">
            <Link to="https://www.facebook.com/penpalcity.official" className="text-white mx-1"><img src="/Images/penpalcity_icons/facebook.png" alt="facebook" title="facebook" style={{ width: "20px", height: "20px" }} /></Link>
            <Link to="https://www.intagram.com/penpalcity" className="text-white mx-1"><img src="/Images/penpalcity_icons/instagram.png" alt="instagram" title="instagram" style={{ width: "20px", height: "20px" }} /></Link>
            <Link to="https://www.twitter.com/penpalcity" className="text-white mx-1"><img src="/Images/penpalcity_icons/twitter.png" alt="X" title="X" style={{ width: "20px", height: "20px" }} /></Link>
          </div>
          <div className="d-flex justify-content-center align-items-center pt-1 d-sm-flex d-md-none">
            <button className="btn btn-outline-success btn-sm" style={{fontSize:"12px"}} onClick={() => setFooterOpenOnlineFriendsModal(true)}>
              Online Friends ({friends.length})
            </button>
          </div>
        </div>
      </footer>
      <Modal
        show={openFooterOnlineFriendsModal}
        onHide={() => setFooterOpenOnlineFriendsModal(false)}
      >
        <Modal.Header
          style={{ fontWeight: "bold", fontSize: "12px", fontStyle: "italic" }}
        >
          Online Friends
        </Modal.Header>
        <Modal.Body>
          <div
            className={`${friends.length > 0
              ? "d-none"
              : "noonlinefriendinfo d-flex flex-column justify-content-center align-items-center"
              }`}
          >
            <i className="fas fa-signal"></i>
            <label>No Online Friends !</label>
          </div>
          <div>
            {friends.map((user) => (
              <div
                key={user.id}
                className="d-flex justify-content-between align-items-center my-1 birthdayitem mx-2"
              >
                <img
                  src={user.profilePicture}
                  alt=""
                  title={user.username}
                />
                <div className="d-flex flex-column justify-content-center align-items-center px-2">
                  <label className="birthdaynamelabel" onClick={() => { navigate(`/chat/${user.username}/${user.id}`) }}>{user.username}</label>
                  <label className="birthdaycountrylabel">{user.country.name}, {user.age}</label>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <i className="fa-solid fa-eye birthdayeye" onClick={() => navigate(`/${user.username}/${user.id}`)}></i>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
