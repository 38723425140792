import { BlockOutlined, CancelOutlined, EmailOutlined, FlagOutlined, PeopleOutlined, PersonAddAlt1Outlined, PersonRemoveOutlined, ThumbDownAltOutlined, ThumbUpAltOutlined } from '@mui/icons-material'
import React, { useState } from 'react'
import User from '../../utilities/user/User'
import LanguageLevels from '../../utilities/languagelevel/LanguageLevels'
import { Modal } from 'react-bootstrap'

export default function UserMain({ user, friends, show, setShow, navigate, friendship, block, sendReport, openReport, deleteBlock, addBlock, acceptFriendship, deleteFriendship, sendFriendshipRequest, showReport, setShowReport, setWords, currentUser }) {
    const [friendsModal, setFriendsModal] = useState(false);
    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center px-2 editprofilemain">
                <div className="bg-white rounded images p-2 d-flex justify-content-center align-items-center">
                    <div
                        className="backgroundimage rounded"
                        style={{
                            backgroundImage:
                                `url(${user.backgroundPicture})`,
                        }}
                    >
                        <div className="changebuttons d-flex justify-content-between align-items-center">
                            <div
                                className="profileimage rounded-circle"
                                style={{
                                    backgroundImage:
                                        `url(${user.profilePicture})`,
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center w-100 px-2 py-1 bg-white rounded my-1 userinformation">
                    <label>{user.username}</label>
                    <label>{user.age}</label>
                    <label>{user.country.name}</label>
                    <i className={`${user.username === currentUser.username ? "d-none" : "fa-solid fa-gear"}`} onClick={() => setShow(true)}></i>
                </div>
                <div className="w-100 p-1 bg-white rounded px-2 aboutsection">
                    <div className="d-flex justify-content-between align-items-center abouthead">
                        <label>About</label>
                    </div>
                    <p className="about py-1">
                        {user.about}
                    </p>
                </div>
                <div className="w-100 p-1 bg-white rounded px-2 hobbiessection">
                    <div className="d-flex justify-content-between align-items-center hobbieshead">
                        <label>Hobbies</label>
                    </div>
                    <p className="hobbies py-1">
                        {user.hobbies}
                    </p>
                </div>
                <div className="w-100 p-1 bg-white rounded px-2 favouritessection">
                    <div className="d-flex justify-content-between align-items-center favouriteshead">
                        <label>Favourites</label>
                    </div>
                    <p className="favourites py-1">
                        {user.favourites}
                    </p>
                </div>
                <div className="w-100 p-1 bg-white rounded px-2 personallanguagesection">
                    <div className="d-flex justify-content-between align-items-center personallanguage">
                        <label>Languages</label>
                    </div>
                    <div className="row px-2">
                        <div className="col-6 col-md-6">
                            <div className="d-flex justify-content-between align-items-center mt-1 pb-1 speaklanguage">
                                <label>Speak</label>
                            </div>
                            {user.languageKnowns?.map(language => (
                                <div className="row px-2 mt-1 languageitem" key={language.id}>
                                    <div className="col-5 col-md-5">{language.language}</div>
                                    <div className="col-5 col-md-5">
                                        <LanguageLevels level={language.level} />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col-6 col-md-6">
                            <div className="d-flex justify-content-between align-items-center mt-1 pb-1 learnlanguage">
                                <label>Learn</label>
                            </div>
                            {user.languageLearnings?.map(language => (
                                <div className="row px-2 mt-1 languageitem" key={language.id}>
                                    <div className="col-5 col-md-5">{language.language}</div>
                                    <div className="col-5 col-md-5">
                                        <LanguageLevels level={language.level} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="w-100 p-1 bg-white rounded px-2 userfriends">
                    <div className="d-flex justify-content-between align-items-center userfriend mb-1">
                        <label className="userfriendsseeall" onClick={() => setFriendsModal(true)}>See All</label>
                        <label>{user.username}'s Friends</label>
                    </div>
                    <div className={`${friends.length > 0 ? "d-none" : "nouserfriendinfo d-flex flex-column justify-content-center align-items-center"}`}>
                        <PeopleOutlined className="userfriendicon" />
                        <label>No Friends !</label>
                    </div>
                    <div className="row px-2">
                        {friends.slice(0, 4).map(friend => (
                            <User key={friend.id} user={friend} />
                        ))
                        }
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Body>
                    <hr />
                    <div className={`${block === "" ? 'd-block' : "d-none"}`}>
                        <div className='d-flex justify-content-between align-items-center px-2 text-primary' style={{ fontSize: "12px" }}>
                            <EmailOutlined style={{ fontSize: "18px", cursor: "pointer" }} onClick={() => navigate(`/chat/${user.username}/${user.id}`)} />
                            <label>Send Message</label>
                        </div>
                        <hr />
                    </div>
                    <div className={`${friendship === "" && block === "" ? 'd-block' : "d-none"}`}>
                        <div className='d-flex justify-content-between align-items-center px-2 text-info' style={{ fontSize: "12px" }}>
                            <PersonAddAlt1Outlined style={{ fontSize: "18px", cursor: "pointer" }} onClick={() => sendFriendshipRequest()} />
                            <label>Send Friendship Request</label>
                        </div>
                        <hr />
                    </div>
                    <div className={`${friendship !== "" && friendship.username2 === user.username && block === "" ? 'd-block' : "d-none"}`}>
                        <div className='d-flex justify-content-between align-items-center px-2 text-danger' style={{ fontSize: "12px" }}>
                            <CancelOutlined style={{ fontSize: "18px", cursor: "pointer" }} onClick={() => deleteFriendship()} />
                            <label>Cancel Friendship Request</label>
                        </div>
                        <hr />
                    </div>
                    <div className={`${friendship !== "" && friendship.username1 === user.username && friendship.friendshipValue != 1 && block === "" ? 'd-block' : "d-none"}`}>
                        <div className='d-flex justify-content-between align-items-center px-2 text-success' style={{ fontSize: "12px" }}>
                            <ThumbUpAltOutlined style={{ fontSize: "18px", cursor: "pointer" }} onClick={() => acceptFriendship()} />
                            <label>Accept Friendship Request</label>
                        </div>
                        <hr />
                    </div>
                    <div className={`${friendship !== "" && friendship.username1 === user.username && friendship.friendshipValue != 1 && block === "" ? 'd-block' : "d-none"}`}>
                        <div className='d-flex justify-content-between align-items-center px-2 text-danger' style={{ fontSize: "12px" }}>
                            <ThumbDownAltOutlined style={{ fontSize: "18px", cursor: "pointer" }} onClick={() => deleteFriendship()} />
                            <label>Reject Friendship Request</label>
                        </div>
                        <hr />
                    </div>
                    <div className={`${friendship !== "" && friendship.friendshipValue === 1 && block === "" ? 'd-block' : "d-none"}`}>
                        <div className='d-flex justify-content-between align-items-center px-2 text-danger' style={{ fontSize: "12px" }}>
                            <PersonRemoveOutlined style={{ fontSize: "18px", cursor: "pointer" }} onClick={() => deleteFriendship()} />
                            <label>Delete From Friendship</label>
                        </div>
                        <hr />
                    </div>
                    <div className={`${block === "" ? 'd-block' : "d-none"}`}>
                        <div className='d-flex justify-content-between align-items-center px-2 text-danger' style={{ fontSize: "12px" }}>
                            <BlockOutlined style={{ fontSize: "18px", cursor: "pointer" }} onClick={() => addBlock()} />
                            <label>Block</label>
                        </div>
                        <hr />
                    </div>
                    <div className={`${block === "" ? 'd-block' : "d-none"}`}>
                        <div className='d-flex justify-content-between align-items-center px-2 text-warning' style={{ fontSize: "12px" }}>
                            <FlagOutlined style={{ fontSize: "18px", cursor: "pointer" }} onClick={() => openReport()} />
                            <label>Report</label>
                        </div>
                        <hr />
                    </div>
                    <div className={`${block !== "" && block.blockingUsername === user.username ? 'alert alert-danger d-flex justify-content-center align-items-center' : "d-none"}`} style={{ fontSize: "12px" }}>
                        You are not allowed to contact {user.username} !
                    </div>
                    <div className={`${block !== "" && block.blockedUsername === user.username ? 'alert alert-danger d-flex justify-content-between align-items-center' : "d-none"}`} style={{ fontSize: "12px" }}>
                        Remove block to contact {user.username} ! <label style={{ cursor: "pointer" }} onClick={() => deleteBlock()}>Remove block ?</label>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showReport} onHide={() => setShowReport(false)}>
                <Modal.Body>
                    <textarea className='form form-control' name='words' onChange={(e) => setWords(e.target.value)} style={{ resize: "none", fontSize: "11px" }} rows={5} placeholder={`Please describe your problem about ${user.username} !`} required></textarea>
                    <hr />
                    <div className='d-flex justify-content-end align-items-center px-2'>
                        <button className='btn btn-outline-secondary btn-sm me-2' onClick={() => setShowReport(false)}>Close</button>
                        <button className='btn btn-outline-primary btn-sm' onClick={() => sendReport()}>Send Report</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={friendsModal} onHide={() => setFriendsModal(false)}>
                <Modal.Body>
                    <div style={{ fontSize: "13px", fontStyle: "italic", fontWeight: "bolder" }}>
                        {user.username}'s Friends
                    </div>
                    <hr />
                    <div className="row px-2">
                        {friends.map(friend => (
                            <User key={friend.id} user={friend} />
                        ))
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
