import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import url from "../../utilities/url";
import Navbar from "../../utilities/navbar/Navbar";
import Footer from "../../utilities/footer/Footer";
import "./User.css"
import UserMain from "./UserMain";
import LeftSidebar from "../../utilities/sidebars/LeftSidebar"
import RightSidebar from "../../utilities/sidebars/RightSidebar"
import alertify from "alertifyjs"

export default function UserProfile() {
  const { username, id } = useParams();

  const [userDetails, setUserDetails] = useState({});
  const [friends, setFriends] = useState([]);
  const [show, setShow] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [block, setBlock] = useState({});
  const [friendship, setFriendship] = useState({});
  const [words, setWords] = useState("");

  var navigate = useNavigate();
  var localUser = localStorage.getItem("user");
  var user = JSON.parse(localUser);

  useEffect(() => {
    var localUser = localStorage.getItem("user");
    if (localUser === null) {
      navigate("/");
    } else {
      getUserDetails();
      getUserFriends();
      getBlock();
      getFriendships();
    }
  }, [navigate]);

  const getUserDetails = () => {
    axios
      .get(url + "/users/" + id)
      .then((response) => response.data)
      .then((data) => setUserDetails(data));
  };

  const getUserFriends = () => {
    axios
      .get(url + "/friendships?userId=" + id)
      .then((response) => response.data)
      .then((data) => setFriends(data));
  };

  const getBlock = () => {
    axios
      .get(url + `/blocks/oneblock?id=${id}&username=${user.username}`)
      .then((response) => response.data)
      .then((data) => setBlock(data));
  };

  const getFriendships = () => {
    axios
      .get(url + `/friendships/onefriend?id=${id}&username=${user.username}`)
      .then((response) => response.data)
      .then((data) => setFriendship(data));
  };

  const sendFriendshipRequest = () => {
    var body = {
      friendshipId: user.username + username,
      username1: user.username,
      username1Id: user.id,
      username2: username,
      username2Id: id,
      friendshipValue: 0,
    };
    axios.post(url + "/friendships", body).then((data) => getFriendships());
    if (userDetails.login.deviceToken !== "yok") {
      axios
        .post(
          url +
          `/sendrequestnotification?deviceToken=${userDetails.login.deviceToken}&username=${user.username}`
        )
        .catch((error) => { });
    }
  };

  const deleteFriendship = () => {
    axios
      .delete(url + "/friendships?friendshipId=" + friendship.friendshipId)
      .then((data) => getFriendships());
  };

  const acceptFriendship = () => {
    var body = [
      {
        path: "friendshipValue",
        op: "replace",
        value: 1,
      },
    ];

    axios
      .patch(url + "/friendships/" + friendship.friendshipId, body)
      .then((data) => getFriendships());

    if (userDetails.login.deviceToken !== "yok") {
      axios.post(
        url +
        `/sendacceptednotification?deviceToken=${userDetails.login.deviceToken}&username=${user.username}`
      );
    }
  };

  const addBlock = () => {
    var body = {
      blockingUsername: user.username,
      blockedUsername: username,
      userId: id,
    };
    axios.post(url + "/blocks", body).then((data) => getBlock());
  };

  const deleteBlock = () => {
    axios
      .delete(
        url + `/blocks?UsernameOne=${user.username}&UsernameTwo=${username}`
      )
      .then((data) => getBlock());
  };

  const openReport = () => {
    setShow(false);
    setShowReport(true);
  };

  const sendReport = () => {
    if (words === "") {
      alertify.error(`Please describe your problem about ${username} !`)
    } else {
      var body = {
        reportingUsername: user.username,
        reportedUsername: username,
        reportReason: words,
      };

      axios.post(url + "/reportedusers", body).then(() => {
        setShowReport(false);
        alertify.warning(
          `We have taken your report about ${username}. We will care about it as soon as possible !`
        );
      });
    }
  };

  if (!userDetails.id) return <p>Loading...</p>;

  return (
    <div className="container-fluid" style={{ marginBottom: "60px" }}>
      <Navbar />
      <div className="row" style={{ marginTop: "70px" }}>
        <div className="col-md-3 d-none d-sm-none d-md-block">
          <LeftSidebar />
        </div>
        <div className="col-12 col-md-6">
          <UserMain user={userDetails} friends={friends} show={show} setShow={setShow} navigate={navigate} friendship={friendship} block={block} sendReport={sendReport} openReport={openReport} deleteBlock={deleteBlock} addBlock={addBlock} acceptFriendship={acceptFriendship} deleteFriendship={deleteFriendship} sendFriendshipRequest={sendFriendshipRequest} showReport={showReport} setShowReport={setShowReport} setWords={setWords} currentUser={user}/>
        </div>
        <div className="col-md-3 d-none d-sm-none d-md-block">
          <RightSidebar />
        </div>
      </div>
      <Footer />
    </div>
  );
}
