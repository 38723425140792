import React from "react";
import { Link } from "react-router-dom";

export default function User({ user }) {
  return (
    <div
      className="px-2 col-3 col-md-3 mb-2"
      title={user.about}
    >
      <div className="border border-solid border-1 rounded">
        <div className="d-flex justify-content-end align-items-center">
          <span className={`${user.login.isOnline === true ? "onlinestatus bg-success me-2 mt-1" : "onlinestatus bg-danger me-2 mt-1"}`}></span>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <img
            src={user.profilePicture}
            alt=""
            title=""
            className="rounded-circle userimg"
          />
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <label className={`${user.gender === "Male" ? "usernamelabel text-primary" : "usernamelabel text-danger"}`}>{user.username}</label>
        </div>
        <div className="d-flex justify-content-around align-items-center mx-2 my-1">
          <label className="userage">{user.age}</label>
          <img
            src={user.country.countryFlag}
            alt=""
            title={user.country.name}
            className="usercountryflag"
          />
          <Link to={`/${user.username}/${user.id}`}>
            <i className="fa-solid fa-arrow-right-long userarrow"></i>
          </Link>
        </div>
      </div>
    </div >
  );
}
