import React, { useEffect, useState } from "react";
import Navbar from "../../utilities/navbar/Navbar";
import Footer from "../../utilities/footer/Footer";
import LeftSidebar from "../../utilities/sidebars/LeftSidebar"
import RightSidebar from "../../utilities/sidebars/RightSidebar"
import { useNavigate } from "react-router-dom";
import axios from "axios";
import url from "../../utilities/url";
import "./Online.css"
import OnlineMain from "./OnlineMain";

export default function Online() {
  const [users, setUsers] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [gender, setGender] = useState("");
  const [countries, setCountries] = useState([]);
  const [ages, setAges] = useState([]);
  const [firstAge, setFirstAge] = useState("");
  const [lastAge, setLastAge] = useState("");
  const [languages, setLanguages] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const pageSize = 32;

  const navigate = useNavigate();

  useEffect(() => {
    var localUser = localStorage.getItem("user");
    if (localUser === null) {
      navigate("/");
    } else {
      getCountries();
      getAges();
      getLanguages();
      getUsers();
    }
  }, [navigate]);

  const getUsers = () => {
    var currentCountry = selectedCountry == 1 ? null : selectedCountry;
    axios
      .get(
        url +
        `/onlineusers?pagenumber=1&pagesize=${pageSize}&gender=${gender}&countryId=${currentCountry}&language=${selectedLanguage}&firstage=${firstAge}&lastage=${lastAge}`
      )
      .then((response) => response.data)
      .then((data) => setDatas(data));
  };

  const setDatas = (data) => {
    setUsers(data.users);
    setMetaData(data.metaData);
  };

  const goToPrevious = () => {
    if (metaData.hasPrevious !== false) {
      var currentCountry = selectedCountry == 1 ? null : selectedCountry;
      axios
        .get(
          url +
          `/onlineusers?PageNumber=${metaData.currentPage - 1
          }&PageSize=${pageSize}&Gender=${gender}&CountryId=${currentCountry}&Language=${selectedLanguage}&FirstAge=${firstAge}&LastAge=${lastAge}`
        )
        .then((response) => response.data)
        .then((data) => setDatas(data));
    }
  };

  const goToNext = () => {
    if (metaData.hasPage !== false) {
      var currentCountry = selectedCountry == 1 ? null : selectedCountry;
      axios
        .get(
          url +
          `/onlineusers?PageNumber=${metaData.currentPage + 1
          }&PageSize=${pageSize}&Gender=${gender}&CountryId=${currentCountry}&Language=${selectedLanguage}&FirstAge=${firstAge}&LastAge=${lastAge}`
        )
        .then((response) => response.data)
        .then((data) => setDatas(data));
    }
  };

  const getCountries = () => {
    axios
      .get(url + "/countries")
      .then((response) => response.data)
      .then((data) => setCountries(data));
  };

  const getAges = () => {
    axios
      .get(url + "/ages")
      .then((response) => response.data)
      .then((data) => setAges(data));
  };

  const getLanguages = () => {
    axios
      .get(url + "/languages")
      .then((response) => response.data)
      .then((data) => setLanguages(data));
  };

  if (!users) return <p>Loading...</p>;

  return (
    <div className="container-fluid" style={{ marginBottom: "60px" }}>
      <Navbar />
      <div className="row" style={{ marginTop: "70px" }}>
        <div className="col-md-3 d-none d-sm-none d-md-block">
          <LeftSidebar />
        </div>
        <div className="col-12 col-md-6">
          <OnlineMain users={users} metaData={metaData} countries={countries} ages={ages} languages={languages} setGender={setGender} setSelectedCountry={setSelectedCountry} setSelectedLanguage={setSelectedLanguage} setFirstAge={setFirstAge} setLastAge={setLastAge} getUsers={getUsers} goToPrevious={goToPrevious} goToNext={goToNext}/>
        </div>
        <div className="col-md-3 d-none d-sm-none d-md-block">
          <RightSidebar />
        </div>
      </div>
      <Footer />
    </div>
  );
}
