import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import url from "../../utilities/url";
import LanguageLevels from "../../utilities/languagelevel/LanguageLevels";

export default function AddNewLanguage() {
  const [levels, setlevels] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [myLanguages, setMyLanguages] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [newLanguage, setNewLanguage] = useState("");
  const [newLevel, setNewLevel] = useState("");

  useEffect(() => {
    getLanguages();
    getLevels();
    getMyLanguages();
  }, []);

  const getLanguages = () => {
    axios
      .get(url + "/languages")
      .then((response) => response.data)
      .then((data) => setLanguages(data));
  };

  const getLevels = () => {
    axios
      .get(url + "/levels")
      .then((response) => response.data)
      .then((data) => setlevels(data));
  };

  const getMyLanguages = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    axios
      .get(url + "/speaklist/" + user.id)
      .then((response) => response.data)
      .then((data) => setMyLanguages(data));
  };

  const deleteLanguage = (id, language) => {
    axios.delete(url + "/speaks/" + id).then((data) => getMyLanguages());
    setError(`${language} has been deleted successfully !`);
    setSuccess("");
  };

  const addNewLanguage = (e) => {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("user"));
    if (newLanguage === "") {
      setError("Please choose a language !");
    } else if (newLevel === "") {
      setError("Please choose a level !");
    } else {
      var body = {
        userId: user.id,
        username: user.username,
        language: newLanguage,
        level: newLevel,
      };

      axios.post(url + "/speaks", body).then((data) => getMyLanguages());
      setError("");
      setSuccess(`${newLanguage} has been added successfully !`);
    }
  };

  return (
    <>
      <nav className="bg-white fixed-top row navi px-2">
        <div className="col-5 col-sm-5 col-md-4 d-flex justify-content-start align-items-center">
          <label className="brand">Penpal City</label>
        </div>
      </nav>

      <div className="container-fluid" style={{ marginTop: "150px", marginBottom: "60px" }}>
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            <div className="d-flex justify-content-center align-items-center">
              <div className="container-fluid">
                <div
                  className="alert alert-light px-4 py-3"
                  style={{
                    fontSize: "14px",
                    color: "purple",
                  }}
                >
                  Please save the languages you can speak !
                </div>

                <div
                  className={error === "" ? "d-none" : "alert alert-danger "}
                  style={{ fontSize: "14px" }}
                >
                  {error}
                </div>

                <div
                  className={success === "" ? "d-none" : "alert alert-success"}
                  style={{
                    fontSize: "14px",
                    color: "purple",
                  }}
                >
                  {success}
                </div>
                <hr />
                <form onSubmit={addNewLanguage}>
                  <div
                    className="d-flex justify-content-between align-items-center pb-1 mt-1"
                    style={{ borderBottomStyle: "solid", borderBottomWidth: "1px" }}
                  >
                    <select
                      name="newLanguage"
                      className="form form-control flex-1 mx-1 mt-1"
                      style={{ fontSize: "11px" }}
                      onChange={(e) => setNewLanguage(e.target.value)}
                      required
                    >
                      {languages.map((l) => (
                        <option key={l.id} value={l.value}>
                          {l.name}
                        </option>
                      ))}
                    </select>
                    <select
                      name="newLevel"
                      className="form form-control flex-1 mx-1 mt-1"
                      onChange={(e) => setNewLevel(e.target.value)}
                      style={{ fontSize: "11px" }}
                      required
                    >
                      {levels.map((l) => (
                        <option key={l.id} value={l.value}>
                          {l.name}
                        </option>
                      ))}
                    </select>
                    <button
                      type="submit"
                      className="btn btn-light btn-sm mt-1 text-light"
                      style={{ fontSize: "13px", backgroundColor: "deepskyblue" }}
                    >
                      <i className="fa-solid fa-plus"></i>
                    </button>
                  </div>
                </form>
                <div className="container">
                  {myLanguages.map((lng) => (
                    <div
                      key={lng.id}
                      className="d-flex justify-content-between align-items-center ms-2 my-1"
                      style={{ fontSize: "12px" }}
                    >
                      <label className="w-100 flex-1 justify-content-flex-start">
                        {lng.language}
                      </label>
                      <label className="w-100 flex-1 justify-content-flex-end">
                        <LanguageLevels level={lng.level} />
                      </label>
                      <button
                        className="btn btn-danger btn-sm text-light"
                        onClick={() => deleteLanguage(lng.id, lng.language)}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </button>
                    </div>
                  ))}
                </div>

                <div
                  className={
                    myLanguages.length > 0
                      ? "d-flex justify-content-end align-items-center"
                      : "d-none"
                  }
                  style={{ marginTop: "50px" }}
                >
                  <Link to="/dashboard" className="btn btn-outline-success btn-sm">
                    Go to Dashboard
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
