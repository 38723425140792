import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import url from "../../utilities/url";
import Navbar from "../../utilities/navbar/Navbar";
import Footer from "../../utilities/footer/Footer";
import Main from "./Main";
import LeftSidebar from "../../utilities/sidebars/LeftSidebar";
import RightSidebar from "../../utilities/sidebars/RightSidebar";
import { Modal } from "react-bootstrap";

export default function Dashboard() {

  const [countries, setCountries] = useState([]);
  const [ages, setAges] = useState([]);
  const [users, setUsers] = useState([]);
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [firstAge, setFirstAge] = useState("");
  const [lastAge, setLastAge] = useState("");
  const [metaData, setMetaData] = useState({});
  const [show, setShow] = useState(false);
  const pageSize = 16;

  useEffect(() => {
    var localUser = JSON.parse(localStorage.getItem("user"));
    if (localUser === null) {
      navigate("/");
    } else {
      updateLogin(localUser.id);
      getUserDetails(localUser.id);
      getSuggestedForYouList(localUser.username)
      getCountries();
      getAges();
      getUsers();
    }
  }, [navigate]);



  const getUserDetails = (id) => {
    axios
      .get(url + "/users/" + id)
      .then((response) => response.data)
      .then((data) => isAvailableAppModal(data));
  };

  const isAvailableAppModal = (data) => {
    var appModal = localStorage.getItem("appModal");
    var deviceToken = data.login.deviceToken;
    if (deviceToken === "yok" & appModal === null) {
      setShow(true);
    }
    else {
      setShow(false);
    }
  };

  const getCountries = () => {
    axios
      .get(url + "/countries")
      .then((response) => response.data)
      .then((data) => setCountries(data));
  };

  const getAges = () => {
    axios
      .get(url + "/ages")
      .then((response) => response.data)
      .then((data) => setAges(data));
  };

  const getUsers = () => {
    var currentCountry = country == 1 ? null : country;
    axios
      .get(
        url +
        `/users?PageNumber=1&PageSize=${pageSize}&Gender=${gender}&CountryId=${currentCountry}&FirstAge=${firstAge}&LastAge=${lastAge}`
      )
      .then((response) => response.data)
      .then((data) => setData(data));
  };

  const setData = (data) => {
    setUsers(data.users);
    setMetaData(data.metaData);
  };

  const goToPrevious = () => {
    if (metaData.hasPrevious !== false) {
      var currentCountry = country == 1 ? null : country;
      axios
        .get(
          url +
          `/users?PageNumber=${metaData.currentPage - 1
          }&PageSize=${pageSize}&Gender=${gender}&CountryId=${currentCountry}&FirstAge=${firstAge}&LastAge=${lastAge}`
        )
        .then((response) => response.data)
        .then((data) => setData(data));
    }
  };

  const goToNext = () => {
    if (metaData.hasPage !== false) {
      var currentCountry = country == 1 ? null : country;
      axios
        .get(
          url +
          `/users?PageNumber=${metaData.currentPage + 1
          }&PageSize=${pageSize}&Gender=${gender}&CountryId=${currentCountry}&FirstAge=${firstAge}&LastAge=${lastAge}`
        )
        .then((response) => response.data)
        .then((data) => setData(data));
    }
  };

  const adjustAppInfoModal = () => {
    localStorage.setItem("appModal", true);
    setShow(false);
  };

  var navigate = useNavigate();

  const updateLogin = (id) => {
    axios
      .get(url + "/users/" + id)
      .then((response) => response.data)
      .then((data) => updateLoginData(data));
  };

  const updateLoginData = (data) => {
    var body = {
      userId: data.id,
      username: data.username,
      deviceToken: data.login.deviceToken,
    };

    axios.put(url + "/logins", body);
  };

  const getSuggestedForYouList = (username) => {
    axios.get(`${url}/suggestedforyou?username=${username}`).then(response => response.data).then(data => setSuggestedUsers(data))
  }

  if (!users) return <p>Loading...</p>

  return (
    <>
      <div className="container-fluid" style={{ marginBottom: "60px" }}>
        <Navbar />
        <div className="row dashboardscreen" style={{ marginTop: "70px" }}>
          <div className="col-md-3 d-none d-sm-none d-md-block">
            <LeftSidebar />
          </div>
          <div className="col-12 col-md-6">
            <Main users={users} metaData={metaData} ages={ages} countries={countries} setGender={setGender} setCountry={setCountry} setFirstAge={setFirstAge} setLastAge={setLastAge} goToNext={goToNext} goToPrevious={goToPrevious} getUsers={getUsers} suggestedUsers={suggestedUsers} />
          </div>
          <div className="col-md-3 d-none d-sm-none d-md-block">
            <RightSidebar />
          </div>
        </div>
        <Footer />
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <div className="row p-2">
            <div className="coll-6 col-md-6">
              <img src="/Images/penpalcity_giris.jpg" alt="" title="Penpal City App" style={{ width: "100%", height: "350px", borderStyle: "solid", borderWidth: "1px" }} />
            </div>
            <div className="coll-6 col-md-6 d-flex flex-column justify-content-center align-items-center">
              <p style={{ fontWeight: "bold", fontStyle: "italic", fontSize: "15px", textAlign: "center" }}>Install our mobile app to get notifications about messages, friendship requests and other news.</p>
              <p style={{ fontWeight: "bold", fontStyle: "italic", fontSize: "15px", textAlign: "center" }}>You can install our app from Google Play Store.</p>
              <img src="/Images/playstore.png" alt="" title="Download Penpal City App" style={{ width: "100%", height: "70px" }} />
              <Link className="mt-4 fw-bold fst-italic" to="https://play.google.com/store/apps/details?id=com.penpalcity.net.penpalcity&pli=1">Just Click Here !</Link>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-end align-items-center pe-2">
            <button className="btn btn-outline-primary btn-sm" style={{ fontSize: "11px" }} onClick={() => adjustAppInfoModal()}>Got It</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
