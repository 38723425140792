import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import url from "../../utilities/url";
import "./Register.css"

export default function Register() {
  const [countries, setCountries] = useState([]);
  const [username, setUsername] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("");
  const [countryId, setCountryId] = useState(0);
  const [birthday, setBirthday] = useState("");
  const [isHiddenBirthday, setIsHiddenBirthday] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [errors, setErrors] = useState([]);
  const [birthdayError, setBirthdayError] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [success, setSuccess] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    getCountries();
  }, []);

  const addNewUser = () => {
    document.getElementById("registerButton").setAttribute("disabled", "true");

    if (birthday === "") {
      document.getElementById("registerButton").removeAttribute("disabled");
      setBirthdayError("Invalid Birthday !");
    } else if (isChecked === false) {
      setBirthdayError("");
      document.getElementById("registerButton").removeAttribute("disabled");
      setConfirmError("Please confirm the Terms of Service !");
    } else {
      setBirthdayError("");
      setConfirmError("");
      var user = {
        username,
        mail,
        password,
        gender,
        countryId,
        birthday,
        isHiddenBirthday,
      };

      axios
        .post(`${url}/users?devicetoken=yok`, user)
        .then((response) =>
          response.data.statusCode === 200
            ? navigateUser(response.data.user)
            : setErrorsFunction(response.data.errorList)
        );
    }
  };

  const getCountries = () => {
    axios
      .get(`${url}/countries`)
      .then((response) => setCountries(response.data));
  };

  const navigateUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    setSuccess("You have successfully registered. Please wait !");
    setTimeout(() => {
      navigate("/addlanguage");
    }, 3000);
  };

  const setErrorsFunction = (errorList) => {
    setErrors(errorList);
    document.getElementById("registerButton").removeAttribute("disabled");
  };

  return (
    <>
      <nav className="bg-white fixed-top row navi px-2">
        <div className="col-5 col-sm-5 col-md-4 d-flex justify-content-start align-items-center">
          <label className="brand">Penpal City</label>
        </div>
      </nav>
      <div className="d-flex justify-content-center align-items-center registerpage">
        <div className="container">
          <div className="row">
            <div className="offset-md-3 col-md-6">
              <div className="d-flex flex-column justify-content-center align-items-center px-4 rounded login">
                <div className="rounded-circle">
                  <img
                    className="loginlogo"
                    src="/Images/penpalcity_logo_new.png"
                    alt="Penpal City"
                    title="Penpal City"
                  />
                </div>
                <div
                  className={`erroralert ${success === "" ? "d-none" : "alert alert-success"
                    }`}
                >
                  {success}
                </div>
                <div className="input-group mb-3 inputitem">
                  <span className="input-group-text">Username</span>
                  <input
                    type="text"
                    className="form-control"
                    name="username"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div
                  className={`erroralert ${errors.filter((e) => e.key === "Username").length > 0
                    ? "alert alert-danger"
                    : "d-none"
                    }`}
                >
                  {errors
                    .filter((e) => e.key === "Username")
                    .map((a) =>
                      a.value.map((b) => (
                        <label key={b} className="w-100">
                          {b}
                        </label>
                      ))
                    )}
                </div>
                <div className="input-group mb-3 inputitem">
                  <span className="input-group-text">Mail</span>
                  <input
                    type="email"
                    className="form-control"
                    name="mail"
                    onChange={(e) => setMail(e.target.value)}
                  />
                </div>
                <div
                  className={`erroralert ${errors.filter((e) => e.key === "Mail").length > 0
                    ? "alert alert-danger"
                    : "d-none"
                    }`}
                >
                  {errors
                    .filter((e) => e.key === "Mail")
                    .map((a) =>
                      a.value.map((b) => (
                        <label key={b} className="w-100">
                          {b}
                        </label>
                      ))
                    )}
                </div>
                <div className="input-group mb-3 inputitem">
                  <span className="input-group-text">Password</span>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div
                  className={`erroralert ${errors.filter((e) => e.key === "Password").length > 0
                    ? "alert alert-danger"
                    : "d-none"
                    }`}
                >
                  {errors
                    .filter((e) => e.key === "Password")
                    .map((a) =>
                      a.value.map((b) => (
                        <label key={b} className="w-100">
                          {b}
                        </label>
                      ))
                    )}
                </div>
                <div className="input-group mb-3 inputitem">
                  <span className="input-group-text">Birthday</span>
                  <input
                    type="date"
                    className="form-control"
                    name="birthday"
                    onChange={(e) => setBirthday(e.target.value)}
                  />
                </div>
                <div
                  className={`erroralert ${birthdayError === "" ? "d-none" : "alert alert-danger"
                    }`}
                >
                  {birthdayError}
                </div>
                <div
                  className={`erroralert ${errors.filter((e) => e.key === "Birthday").length > 0
                    ? "alert alert-danger"
                    : "d-none"
                    }`}
                >
                  {errors
                    .filter((e) => e.key === "Birthday")
                    .map((a) =>
                      a.value.map((b) => (
                        <label key={b} className="w-100">
                          {b}
                        </label>
                      ))
                    )}
                </div>
                <div className="input-group mb-3 inputitem">
                  <div className="input-group-text">
                    <input
                      className="form-check-input mt-0"
                      type="checkbox"
                      value=""
                      aria-label="Checkbox for following text input"
                      name="isBirthdayHidden"
                      checked={isHiddenBirthday}
                      onChange={(e) => setIsHiddenBirthday(e.target.checked)}
                    />
                  </div>
                  <input
                    id="registercheck"
                    type="text"
                    className="form-control"
                    aria-label="Text input with checkbox"
                    placeholder="Other users can see when it is my birthday !"
                    disabled
                  />
                </div>
                <div className="input-group mb-3 inputitem">
                  <span className="input-group-text">Gender</span>
                  <select
                    className="form-control"
                    name="gender"
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div
                  className={`erroralert ${errors.filter((e) => e.key === "Gender").length > 0
                    ? "alert alert-danger"
                    : "d-none"
                    }`}
                >
                  {errors
                    .filter((e) => e.key === "Gender")
                    .map((a) =>
                      a.value.map((b) => (
                        <label key={b} className="w-100">
                          {b}
                        </label>
                      ))
                    )}
                </div>
                <div className="input-group mb-3 inputitem">
                  <span className="input-group-text">Country</span>
                  <select
                    className="form-control"
                    name="countryId"
                    onChange={(e) => setCountryId(parseInt(e.target.value))}
                  >
                    <option value="0">Select Country</option>
                    {countries.map((c) => (
                      <option key={c.id} value={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className={`erroralert ${errors.filter((e) => e.key === "CountryId").length > 0
                    ? "alert alert-danger"
                    : "d-none"
                    }`}
                >
                  {errors
                    .filter((e) => e.key === "CountryId")
                    .map((a) =>
                      a.value.map((b) => (
                        <label key={b} className="w-100">
                          {b}
                        </label>
                      ))
                    )}
                </div>
                <div className="input-group mb-3 inputitem">
                  <div className="input-group-text">
                    <input
                      className="form-check-input mt-0"
                      type="checkbox"
                      value=""
                      aria-label="Checkbox for following text input"
                      name="isChecked"
                      checked={isChecked}
                      onChange={(e) => setIsChecked(e.target.checked)}
                    />
                  </div>
                  <label className="p-2">
                    I have read and confirmed{" "}
                    <Link to="/termsofservices">the Terms of Service</Link> !
                  </label>
                </div>
                <div
                  className={`erroralert ${confirmError === "" ? "d-none" : "alert alert-danger"
                    }`}
                >
                  {confirmError}
                </div>
                <div className="d-flex justify-content-end align-items-center w-100 inputitem">
                  <button
                    id="registerButton"
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => addNewUser()}
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
