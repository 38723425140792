import React, { useState } from 'react'
import LanguageLevel from '../../../utilities/languagelevel/LanguageLevels'
import { AddCircleOutlineOutlined, RemoveCircleOutlineOutlined } from '@mui/icons-material'
import { Modal } from 'react-bootstrap'

export default function PersonalLanguages({ user, languages, levels, setSpeakLng, setSpeakLvl, setLearnLng, setLearnLvl, addNewSpeak, addNewLearn, deleteSpeak, deleteLearn }) {
  const [show, setShow] = useState(false);
  const [showLearn, setShowLearn] = useState(false);
  return (
    <>
      <div className="w-100 p-1 bg-white rounded px-2 personallanguagesection">
        <div className="d-flex justify-content-between align-items-center personallanguage">
          <label>Languages</label>
        </div>
        <div className="row px-2">
          <div className="col-6 col-md-6">
            <div className="d-flex justify-content-between align-items-center mt-1 pb-1 speaklanguage">
              <label>Speak</label>
              <div className="bg-secondary text-white rounded-circle me-2 d-flex justify-content-center align-items-center" onClick={() => setShow(true)}>
                <AddCircleOutlineOutlined />
              </div>
            </div>
            {user.languageKnowns?.map(language => (
              <div className="row px-2 mt-1 languageitem" key={language.id}>
                <div className="col-5 col-md-5">{language.language}</div>
                <div className="col-5 col-md-5">
                  <LanguageLevel level={language.level} />
                </div>
                <div className="col-1 col-md-1 delete" onClick={() => deleteSpeak(language.id, language.language)}>
                  <RemoveCircleOutlineOutlined style={{ fontSize: "15px", color: "red", cursor: "pointer" }} />
                </div>
              </div>
            ))}
          </div>
          <div className="col-6 col-md-6">
            <div className="d-flex justify-content-between align-items-center mt-1 pb-1 learnlanguage">
              <label>Learn</label>
              <div className="bg-secondary text-white rounded-circle me-2 d-flex justify-content-center align-items-center" onClick={() => setShowLearn(true)}>
                <AddCircleOutlineOutlined />
              </div>
            </div>
            {user.languageLearnings?.map(language => (
              <div className="row px-2 mt-1 languageitem" key={language.id}>
                <div className="col-5 col-md-5">{language.language}</div>
                <div className="col-5 col-md-5">
                  <LanguageLevel level={language.level} />
                </div>
                <div className="col-1 col-md-1 delete" onClick={() => deleteLearn(language.id, language.language)}>
                  <RemoveCircleOutlineOutlined style={{ fontSize: "15px", color: "red", cursor: "pointer" }} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <div className='px-4 fw-bold fst-italic' style={{ fontSize: "13px" }}>Add New Language</div>
          <hr />
          <div className='d-flex justify-content-between align-items-center px-2'>
            <select className='form-control mx-1' onChange={(e) => setSpeakLng(e.target.value)} style={{ fontSize: "11px" }}>
              {languages.map(lng => (
                <option key={lng.id} value={lng.value}>{lng.name}</option>
              ))}
            </select>
            <select className='form-control mx-1' onChange={(e) => setSpeakLvl(e.target.value)} style={{ fontSize: "11px" }}>
              {levels.map(lvl => (
                <option key={lvl.id} value={lvl.value}>{lvl.name}</option>
              ))}
            </select>
            <button className='btn btn-outline-primary btn-sm mx-1 d-flex justify-content-center align-items-center' onClick={() => addNewSpeak()}><AddCircleOutlineOutlined style={{ fontSize: "18px" }} /></button>
          </div>
          <hr />
          <div className="d-flex justify-content-end align-items-center px2">
            <button className="btn btn-secondary btn-sm" onClick={() => setShow(false)}>Close</button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showLearn} onHide={() => setShowLearn(false)}>
        <Modal.Body>
          <div className='px-4 fw-bold fst-italic' style={{ fontSize: "13px" }}>Add New Language</div>
          <hr />
          <div className='d-flex justify-content-between align-items-center px-2'>
            <select className='form-control mx-1' onChange={(e) => setLearnLng(e.target.value)} style={{ fontSize: "11px" }}>
              {languages.map(lng => (
                <option key={lng.id} value={lng.value}>{lng.name}</option>
              ))}
            </select>
            <select className='form-control mx-1' onChange={(e) => setLearnLvl(e.target.value)} style={{ fontSize: "11px" }}>
              {levels.map(lvl => (
                <option key={lvl.id} value={lvl.value}>{lvl.name}</option>
              ))}
            </select>
            <button className='btn btn-outline-primary btn-sm mx-1 d-flex justify-content-center align-items-center' onClick={() => addNewLearn()}><AddCircleOutlineOutlined style={{ fontSize: "18px" }} /></button>
          </div>
          <hr />
          <div className="d-flex justify-content-end align-items-center px2">
            <button className="btn btn-secondary btn-sm" onClick={() => setShowLearn(false)}>Close</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
