import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Pictures from './pictures/Pictures';
import PersonelInformations from './personelinformations/PersonelInformations';
import PersonalLanguages from './personallanguages/PersonalLanguages';
import { Modal } from 'react-bootstrap';

export default function EditProfileMain({ user, updateInfos, profileShow, setProfileShow, backgroundShow, setBackgroundShow, changeProfile, changeBackground, setProfile, setBackground, setMail, setPassword, setBirthday, setIsBirthdayHidden, setAbout, setHobbies, setFavourites, languages, levels, setSpeakLng, setSpeakLvl, setLearnLng, setLearnLvl, addNewSpeak, addNewLearn, deleteSpeak, deleteLearn, deleteUserAccount }) {
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center px-2 editprofilemain">
                <Pictures
                    user={user}
                    profileShow={profileShow}
                    setProfileShow={setProfileShow}
                    backgroundShow={backgroundShow}
                    setBackgroundShow={setBackgroundShow}
                    changeProfile={changeProfile}
                    changeBackground={changeBackground}
                    setProfile={setProfile}
                    setBackground={setBackground}
                />
                <PersonelInformations user={user} updateInfos={updateInfos} setMail={setMail} setPassword={setPassword} setBirthday={setBirthday} setIsBirthdayHidden={setIsBirthdayHidden} setAbout={setAbout} setHobbies={setHobbies} setFavourites={setFavourites} />
                <PersonalLanguages user={user} languages={languages} levels={levels} setSpeakLng={setSpeakLng} setSpeakLvl={setSpeakLvl} setLearnLng={setLearnLng} setLearnLvl={setLearnLvl} addNewSpeak={addNewSpeak} addNewLearn={addNewLearn} deleteSpeak={deleteSpeak} deleteLearn={deleteLearn} />
                <div className="w-100 bg-white px-2 py-1 mt-2 rounded d-flex justify-content-between align-items-center">
                    <Link to={`/${user.username}/${user.id}`} className="text-primary personallinks">
                        Preview Your Profile
                    </Link>
                    <label className="text-danger personallinks" style={{ cursor: "pointer" }} onClick={() => setOpenDeleteModal(true)}>
                        Delete Account
                    </label>
                </div>
            </div>
            <Modal show={openDeleteModal} onHide={() => setOpenDeleteModal(false)}>
                <Modal.Body>
                    <div className='d-flex justify-content-center align-items-center'>
                        <img src="/Images/sad.png" alt='' style={{ height: "250px" }} />
                    </div>
                    <div className="fw-bold mx-2 my-2">
                        We are sorry you are leaving. We will miss you ! Are you sure to
                        delete your account ?
                    </div>
                    <hr />
                    <div className='d-flex justify-content-around align-items-center px4'>
                        <button className='btn btn-outline-secondary btn-sm' onClick={() => setOpenDeleteModal(false)}>Cancel</button>
                        <button className='btn btn-outline-danger btn-sm' onClick={() => deleteUserAccount()}>Confirm</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
