import { Route, Routes } from "react-router-dom";
import Login from "./components/login/Login";
import Register from "./components/register/Register";
import Dashboard from "./components/dashboard/Dashboard";
import AddNewLanguage from "./components/addnewlanguage/AddNewLanguage";
import UserProfile from "./components/userprofile/UserProfile";
import EditProfile from "./components/editprofile/EditProfile";
import MyMessages from "./components/mymessages/MyMessages";
import Friends from "./components/friends/Friends";
import Online from "./components/online/Online";
import DetailedSearch from "./components/detailedsearch/DetailedSearch";
import Chat from "./components/chat/Chat";
import TermsOfService from "./components/termsofservice/TermsOfService";
import PrivacyPolicy from "./components/privacypolicy/PrivacyPolicy";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/addlanguage" element={<AddNewLanguage />} />
        <Route path="/:username/:id" element={<UserProfile />} />
        <Route path="/editprofile" element={<EditProfile />} />
        <Route path="/messages" element={<MyMessages />} />
        <Route path="/friends" element={<Friends />} />
        <Route path="/online" element={<Online />} />
        <Route path="/detailedsearch" element={<DetailedSearch />} />
        <Route path="/chat/:username/:id" element={<Chat />} />
        <Route path="/termsofservices" element={<TermsOfService />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      </Routes>
    </div>
  );
}

export default App;
