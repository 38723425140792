import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

export default function Languages() {

  const navigate = useNavigate();

  const setLanguage = (language) => {
    localStorage.setItem("currentLanguage", language);
    navigate("/detailedsearch");
  }

  return (
    <div className="w-100 p-1 bg-white rounded px-2 languages">
      <div className="d-flex justify-content-end align-items-center language">
        <label className="px-2">Languages</label>
      </div>
      <div className="row px-2 mt-1">
        <div className="col-3 col-md-2 p-1 ">
          <div className="rounded countryflags d-flex flex-column justify-content-center align-items-center py-2">
            <img
              src="/Images/CountryFlags/United_Kingdom.jpg"
              alt="English"
              title="English"
            />
            <label onClick={() => setLanguage("English")}>English</label>
          </div>
        </div>

        <div className="col-3 col-md-2 p-1 ">
          <div className="rounded countryflags d-flex flex-column justify-content-center align-items-center py-2">
            <img
              src="/Images/CountryFlags/Germany.jpg"
              alt="English"
              title="English"
            />
            <label onClick={() => setLanguage("German")}>German</label>
          </div>
        </div>

        <div className="col-3 col-md-2 p-1 ">
          <div className="rounded countryflags d-flex flex-column justify-content-center align-items-center py-2">
            <img
              src="/Images/CountryFlags/Italy.jpg"
              alt="English"
              title="English"
            />
            <label onClick={() => setLanguage("Italian")}>Italian</label>
          </div>
        </div>

        <div className="col-3 col-md-2 p-1 ">
          <div className="rounded countryflags d-flex flex-column justify-content-center align-items-center py-2">
            <img
              src="/Images/CountryFlags/Spain.jpg"
              alt="English"
              title="English"
            />
            <label onClick={() => setLanguage("Spanish")}>Spanish</label>
          </div>
        </div>

        <div className="col-3 col-md-2 p-1 ">
          <div className="rounded countryflags d-flex flex-column justify-content-center align-items-center py-2">
            <img
              src="/Images/CountryFlags/South_Korea.jpg"
              alt="English"
              title="English"
            />
            <label onClick={() => setLanguage("Korean")}>Korean</label>
          </div>
        </div>

        <div className="col-3 col-md-2 p-1 ">
          <div className="rounded countryflags d-flex flex-column justify-content-center align-items-center py-2">
            <img
              src="/Images/CountryFlags/Japan.jpg"
              alt="English"
              title="English"
            />
            <label onClick={() => setLanguage("Japanese")}>Japanese</label>
          </div>
        </div>

        <div className="col-3 col-md-2 p-1 ">
          <div className="rounded countryflags d-flex flex-column justify-content-center align-items-center py-2">
            <img
              src="/Images/CountryFlags/Turkiye.jpg"
              alt="English"
              title="English"
            />
            <label onClick={() => setLanguage("Turkish")}>Turkish</label>
          </div>
        </div>

        <div className="col-3 col-md-2 p-1 ">
          <div className="rounded countryflags d-flex flex-column justify-content-center align-items-center py-2">
            <img
              src="/Images/CountryFlags/China.jpg"
              alt="English"
              title="English"
            />
            <label onClick={() => setLanguage("Chinese")}>Chinese</label>
          </div>
        </div>

        <div className="col-3 col-md-2 p-1 ">
          <div className="rounded countryflags d-flex flex-column justify-content-center align-items-center py-2">
            <img
              src="/Images/CountryFlags/Saudi_Arabia.jpg"
              alt="English"
              title="English"
            />
            <label onClick={() => setLanguage("Arabic")}>Arabic</label>
          </div>
        </div>

        <div className="col-9 col-md-6 d-flex justify-content-center align-items-center text-info">
          <Link to="/detailedsearch" onClick={() => localStorage.setItem("currentLanguage", "")}><label>See More</label></Link>
        </div>
      </div>
    </div>
  )
}
