import { PersonAddAlt1Outlined } from '@mui/icons-material';
import Badge from '@mui/material/Badge';
import React, { useState } from 'react'
import Friend from './Friend';
import { Modal } from 'react-bootstrap';
import Request from '../../utilities/sidebars/friendshiprequests/Request';

export default function FriendsMain({ friends, id, requests, acceptFriendship, deleteFriendship }) {
    const [show, setShow] = useState(false);
    return (
        <>
            <div className="w-100 p-1 bg-white rounded px-2 friendsection">
                <div className="d-flex justify-content-between align-items-center friendlisthead">
                    <label>My Friends</label>
                    <Badge
                        badgeContent={requests.length}
                        color="secondary"
                        showZero={false}
                        onClick={() => setShow(true)}
                    >
                        <PersonAddAlt1Outlined
                            color="action"
                            style={{
                                fontWeight: "bold",
                                color: "purple",
                                cursor: "pointer",
                                fontSize: "17px",
                            }}
                        />
                    </Badge>
                </div>
                <div className={`${friends.length > 0 ? "d-none" : "nonewmessagesinfo d-flex flex-column justify-content-center align-items-center"}`}>
                    <i className="fa-solid fa-users-slash"></i>
                    <label>No Friends !</label>
                </div>
                <div className="row p-2">
                    {friends.map(friend => (
                        <Friend key={friend.id} friend={friend} id={id} deleteFriendship={deleteFriendship} />
                    ))}
                </div>
            </div>
            <Modal
                className="friendRequestModal"
                show={show}
                onHide={() => setShow(false)}
            >
                <Modal.Body>
                    <div className="modalBodyFriendRequest">
                        <div className="d-flex justify-content-between align-items-center friendshiprequesthead">
                            <label>Friendship Requests</label>
                        </div>
                        <div className={`${requests.length > 0 ? "d-none" : "norequestinfo d-flex flex-column justify-content-center align-items-center"}`}>
                            <i className="fa-solid fa-handshake-slash"></i>
                            <label>No Friendship Requests !</label>
                        </div>
                        {requests.map(request => (
                            <Request key={request.id} user={request} id={id} acceptFriendship={acceptFriendship} deleteFriendship={deleteFriendship} />
                        ))
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
