import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import url from "../../url";

export default function NewMessage({ message }) {
    var currentUser = JSON.parse(localStorage.getItem("user"));
    const navigate = useNavigate()
    const updateMessage = () => {
        var data = [
            {
                path: "messageStatus",
                op: "replace",
                value: "True",
            },
        ];

        axios.patch(
            url +
            `/messageprofiles?currentusername=${currentUser.username}&username=${message.user.username}`,
            data
        );
        navigate(`/chat/${message.user.username}/${message.user.id}`)
    }
    return (
        <>
            <div
                className="newmessageitem px-2 d-flex align-items-center"
                title={message.words}
                onClick={() => updateMessage()}
            >
                <img
                    src={message.user.profilePicture}
                    alt=""
                />
                <div className="d-flex flex-column align-items-center">
                    <div className={`${message.user.gender === "Male" ? "text-primary" : "text-danger"} sender w-100 px-1`}>{message.user.username}</div>
                    <div className={`messagetext ${message.messageStatus === "False" ? "text-danger" : "text-secondary"} message w-100 px-1`}>
                        {message.words}
                    </div>
                </div>
            </div>
            <hr />
        </>
    );
};
