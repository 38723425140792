import React from 'react'
import User from '../../../utilities/user/User'

export default function QuickSearch({ users, metaData, ages, countries, setGender, setCountry, setFirstAge, setLastAge, goToNext, goToPrevious, getUsers }) {


    return (
        <div className="w-100 p-1 bg-white rounded px-2 quicksearchsection">
            <div className="d-flex justify-content-between align-items-center quicksearch">
                <label>Quick Search</label>
                <div>
                    <label className="onlinefriendsseeall mx-4">
                        <label className="nav-item dropdown">
                            <span
                                className="nav-link btn py-1"
                                id="navbarDropdown"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Filter
                            </span>
                            <ul
                                className="dropdown-menu px-1"
                                aria-labelledby="navbarDropdown"
                            >
                                <li>
                                    <select
                                        className="form-control my-1"
                                        style={{ fontSize: "10px" }}
                                        onChange={(e) => setGender(e.target.value)}
                                        name='gender'
                                    >
                                        <option value="">Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </li>
                                <li>
                                    <select
                                        className="form-control my-1"
                                        style={{ fontSize: "10px" }}
                                        name='country'
                                        onChange={(e) => setCountry(e.target.value)}
                                    >
                                        {countries.map(country => (
                                            <option key={country.id} value={country.id}>{country.name}</option>
                                        ))}
                                    </select>
                                </li>
                                <li>
                                    <select
                                        className="form-control my-1"
                                        style={{ fontSize: "10px" }}
                                        name='firstAge'
                                        onChange={(e) => setFirstAge(e.target.value)}
                                    >
                                        {ages.map(age => (
                                            <option key={age.id} value={age.value}>{age.name}</option>
                                        ))}
                                    </select>
                                </li>
                                <li>
                                    <select
                                        className="form-control my-1"
                                        style={{ fontSize: "10px" }}
                                        name='lastAge'
                                        onChange={(e) => setLastAge(e.target.value)}
                                    >
                                        {ages.map(age => (
                                            <option key={age.id} value={age.value}>{age.name}</option>
                                        ))}
                                    </select>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <button
                                        className="form-control btn btn-outline-primary btn-sm"
                                        style={{ fontSize: "10px" }}
                                        onClick={() => getUsers()}
                                    >
                                        Filter
                                    </button>
                                </li>
                            </ul>
                        </label>
                    </label>
                    <label className="onlinefriendsseeall mx-2">
                        <i className="fa-solid fa-angle-left fw-bold" onClick={() => goToPrevious()}></i>
                    </label>
                    <label className="onlinefriendsseeall">| {metaData.currentPage} / {metaData.totalPage} |</label>
                    <label className="onlinefriendsseeall mx-2">
                        <i className="fa-solid fa-angle-right fw-bold" onClick={() => goToNext()}></i>
                    </label>
                </div>
            </div>
            <div className={`${metaData.totalCount > 0 ? "d-none" : "nousersinfo d-flex flex-column justify-content-center align-items-center"}`}>
                <i className="fa-solid fa-users-slash"></i>
                <label>No Users Found !</label>
            </div>
            <div className="row px-2 pt-1">
                {users.map(user => (
                    <User key={user.id} user={user} />
                ))
                }
            </div>
        </div>
    )
}
