import React, { useEffect, useState } from "react";
import OnlineFriend from "./OnlineFriend";
import axios from "axios";
import url from "../../url";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function OnlineFriends() {
  const [users, setUsers] = useState([]);
  const [openOnlineFriendsModal, setOpenOnlineFriendsModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getOnlineFriends();
  }, []);

  const getOnlineFriends = () => {
    var currentUser = JSON.parse(localStorage.getItem("user").toString());

    axios
      .get(`${url}/friendships/online?username=${currentUser.username}`)
      .then((response) => response.data)
      .then((data) => setUsers(data));
  };

  const openOnlineFriendListModal = () => {
    setOpenOnlineFriendsModal(true);
  };

  return (
    <>
      <div className="w-100 p-1 bg-white rounded px-2 onlinefriends">
        <div className="d-flex justify-content-between align-items-center onlinefriend">
          <label
            className="onlinefriendsseeall"
            onClick={() => openOnlineFriendListModal()}
          >
            See All
          </label>
          <label>Online Friends</label>
        </div>
        <div
          className={`${users.length > 0
            ? "d-none"
            : "noonlinefriendinfo d-flex flex-column justify-content-center align-items-center"
            }`}
        >
          <i className="fas fa-signal"></i>
          <label>No Online Friends !</label>
        </div>
        <div className="row px-2">
          {users.slice(0, 6).map((user) => (
            <OnlineFriend key={user.id} user={user} />
          ))}
        </div>
      </div>
      <Modal
        show={openOnlineFriendsModal}
        onHide={() => setOpenOnlineFriendsModal(false)}
      >
        <Modal.Header
          style={{ fontWeight: "bold", fontSize: "12px", fontStyle: "italic" }}
        >
          Online Friends
        </Modal.Header>
        <Modal.Body>
          <div
            className={`${users.length > 0
              ? "d-none"
              : "noonlinefriendinfo d-flex flex-column justify-content-center align-items-center"
              }`}
          >
            <i className="fas fa-signal"></i>
            <label>No Online Friends !</label>
          </div>
          <div>
            {users.map((user) => (
              <div
                key={user.id}
                className="d-flex justify-content-between align-items-center my-1 birthdayitem mx-2"
              >
                <img
                  src={user.profilePicture}
                  alt=""
                  title={user.username}
                />
                <div className="d-flex flex-column justify-content-center align-items-center px-2">
                  <label className="birthdaynamelabel" onClick={() => { navigate(`/chat/${user.username}/${user.id}`) }}>{user.username}</label>
                  <label className="birthdaycountrylabel">{user.country.name}, {user.age}</label>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <i className="fa-solid fa-eye birthdayeye" onClick={() => navigate(`/${user.username}/${user.id}`)}></i>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

