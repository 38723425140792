import React from 'react'
import { Modal } from 'react-bootstrap'

export default function Pictures({ user,
    profileShow,
    backgroundShow,
    setProfileShow,
    setBackgroundShow,
    changeProfile,
    changeBackground,
    setProfile,
    setBackground
}) {
    return (
        <>
            <div className="bg-white rounded images p-2 d-flex justify-content-center align-items-center">
                <div
                    className="backgroundimage rounded"
                    style={{
                        backgroundImage:
                            `url(${user.backgroundPicture})`,
                    }}
                >
                    <div className="changebuttons d-flex justify-content-between align-items-center">
                        <div
                            className="profileimage rounded-circle"
                            style={{
                                backgroundImage:
                                    `url(${user.profilePicture})`,
                            }}
                        >
                            <div
                                className="changeprofileimage bg-success rounded-circle d-flex justify-content-center align-items-center"
                                onClick={() => setProfileShow(true)}
                            >
                                <i className="fa-solid fa-pencil"></i>
                            </div>
                        </div>
                        <div
                            className="changebackgroundimage bg-success rounded-circle d-flex justify-content-center align-items-center"
                            onClick={() => setBackgroundShow(true)}
                        >
                            <i className="fa-solid fa-pencil"></i>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={profileShow} onHide={() => setProfileShow(false)}>
                <Modal.Body>
                    <label style={{ fontSize: "13px", fontWeight: "bold", fontStyle: "italic" }}>Change Profile Picture</label>
                    <hr />
                    <div className='d-flex justify-content-between align-items-center px-2'>
                        <input type='file' accept='image/*' className='form-control me-1' style={{ fontSize: "10px" }} onChange={(e) => setProfile(e.target.files[0])} />
                        <button className='btn btn-outline-info btn-sm ms-1' style={{ fontSize: "10px" }} onClick={() => changeProfile()}>Change</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={backgroundShow} onHide={() => setBackgroundShow(false)}>
                <Modal.Body>
                    <label style={{ fontSize: "13px", fontWeight: "bold", fontStyle: "italic" }}>Change Background Picture</label>
                    <hr />
                    <div className='d-flex justify-content-between align-items-center px-2'>
                        <input type='file' accept='image/*' className='form-control me-1' style={{ fontSize: "10px" }} onChange={(e) => setBackground(e.target.files[0])} />
                        <button className='btn btn-outline-info btn-sm ms-1' style={{ fontSize: "10px" }} onClick={() => changeBackground()}>Change</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
