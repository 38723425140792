import { ErrorOutlineOutlined } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import url from "../../url";

export default function Request({ user, acceptFriendship, deleteFriendship, id }) {
    const [friendship, setFriendship] = useState({});
    useEffect(() => {
        getOneFriendship();
    }, []);

    const getOneFriendship = () => {
        axios
            .get(url + `/friendships/onefriend?id=${id}&username=${user.username}`)
            .then((response) => response.data)
            .then((data) => setFriendship(data));
    };
    return (
        <>
            <div className="d-flex justify-content-between align-items-center my-1 requestitem mx-2">
                <img
                    src={user.profilePicture}
                    alt="Profile Resmi"
                    title={user.about}
                />
                <div className="d-flex flex-column justify-content-center align-items-center px-2">
                    <label className="requestnamelabel">{user.username}</label>
                    <label className="requestcountrylabel">{user.country.name}, {user.age}</label>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <label className="nav-item dropdown">
                        <span
                            className="nav-link btn py-1"
                            id="navbarDropdownNav"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <ErrorOutlineOutlined
                                style={{ color: "purple", cursor: "pointer", fontSize: "18px" }}
                            />
                        </span>
                        <ul
                            className="dropdown-menu px-1"
                            aria-labelledby="navbarDropdownNav"
                        >
                            <li>
                                <div className="container">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <i className="fa-solid fa-eye friendsoptions"></i>
                                        <Link to={`/${user.username}/${user.id}`} className="text-dark">
                                            <label className="friendsoptionslabel">See Profile</label>
                                        </Link>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div className="container">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <i className="fa-solid fa-envelope friendsoptions"></i>
                                        <Link to={`/chat/${user.username}/${user.id}`} className="text-dark">
                                            <label className="friendsoptionslabel">Send Message</label>
                                        </Link>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div className="container">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <i className="fa-solid fa-user-plus friendsoptions"></i>
                                        <label className="friendsoptionslabel" onClick={() => acceptFriendship(friendship.friendshipId, user.login.deviceToken)}>
                                            Accept Request
                                        </label>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div className="container">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <i className="fa-solid fa-trash friendsoptions"></i>
                                        <label className="friendsoptionslabel" onClick={() => deleteFriendship(friendship.friendshipId)}>
                                            Delete Request
                                        </label>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </label>
                </div>
            </div>
            <hr />
        </>
    );
};
