import React, { useEffect, useState } from "react";
import Navbar from "../../utilities/navbar/Navbar";
import Footer from "../../utilities/footer/Footer";
import LeftSidebar from "../../utilities/sidebars/LeftSidebar";
import RightSidebar from "../../utilities/sidebars/RightSidebar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import url from "../../utilities/url";
import ChatMain from "./ChatMain";
import alertify from "alertifyjs";

export default function Chat() {
  const { username, id } = useParams();
  const [chats, setChats] = useState([]);
  const [block, setBlock] = useState({});
  const [chatWords, setChatWords] = useState("");
  const [messageId, setMessageId] = useState("");
  const [user, setUser] = useState({});

  var currentUser = JSON.parse(localStorage.getItem("user"));
  var navigate = useNavigate();
  useEffect(() => {
    var localUser = localStorage.getItem("user");
    if (localUser === null) {
      navigate("/");
    } else {
      getChats();
      getOneBlock();
      getMessageId();
      getUserDetails();
    }
  }, [navigate, chats]);

  const getChats = () => {
    axios
      .get(
        url +
        `/chats?currentusername=${currentUser.username}&username=${username}`
      )
      .then((response) => response.data)
      .then((data) => setChats(data));
  };

  const getOneBlock = () => {
    axios
      .get(url + `/blocks/oneblock?id=${id}&username=${currentUser.username}`)
      .then((response) => response.data)
      .then((data) => setBlock(data));
  };

  const getMessageId = () => {
    axios
      .get(
        url +
        `/oneprofile?currentusername=${currentUser.username}&username=${username}`
      )
      .then((response) => response.data)
      .then((data) => setMessageId(data));
  };

  const getUserDetails = () => {
    axios
      .get(url + "/users/" + id)
      .then((response) => response.data)
      .then((data) => setUser(data));
  };

  const addChat = () => {
    if (chatWords === "") {
      alertify.error("Please write a message !")
    } else {
      var chatBody = {
        messageId: messageId,
        sender: currentUser.username,
        receiver: username,
        words: chatWords,
        deleteUsername: "",
      };
      axios.post(url + "/chats", chatBody);

      var messageProfileForCurrentUser = {
        messageId: messageId,
        userId: id,
        username: username,
        username2: currentUser.username,
        messageStatus: "True",
        words: chatWords,
      };
      axios.post(url + "/messageprofiles", messageProfileForCurrentUser);

      var messageProfileForUser = {
        messageId: messageId,
        userId: currentUser.id,
        username: currentUser.username,
        username2: username,
        messageStatus: "False",
        words: chatWords,
      };
      axios.post(url + "/messageprofiles", messageProfileForUser);
      if (user.login.deviceToken !== "yok") {
        axios
          .post(
            url +
            `/sendmessagenotification?deviceToken=${user.login.deviceToken}&username=${currentUser.username}`
          )
          .then((d) => { });
      }
      document.getElementById("chatWords").value = "";
      setChatWords("");
    }
  };

  const deleteChat = (id) => {
    axios.delete(url + "/deleteonechat?id=" + id).then((d) => getChats());
  };

  const addBlock = () => {
    var body = {
      blockingUsername: currentUser.username,
      blockedUsername: username,
      userId: id,
    };
    axios.post(url + "/blocks", body).then((data) => getOneBlock());
  };

  const deleteBlock = () => {
    axios
      .delete(
        url + `/blocks?UsernameOne=${currentUser.username}&UsernameTwo=${username}`
      )
      .then((data) => getOneBlock());
  };

  if (!currentUser || !chats) return <p>Loading...</p>;
  return (
    <>
      <div className="container-fluid">
        <Navbar />
        <div className="row dashboardscreen" style={{ marginTop: "65px" }}>
          <div className="col-md-3 d-none d-sm-none d-md-block" style={{ marginTop: "5px" }}>
            <LeftSidebar />
          </div>
          <div className="col-12 col-md-6">
            <ChatMain user={user} chats={chats} block={block} setChatWords={setChatWords} addChat={addChat} deleteChat={deleteChat} addBlock={addBlock} deleteBlock={deleteBlock} />
          </div>
          <div className="col-md-3 d-none d-sm-none d-md-block" style={{ marginTop: "5px" }}>
            <RightSidebar />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
