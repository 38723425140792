import React, { useEffect, useState } from "react";
import "./Navbar.css";
import Badge from "@mui/material/Badge";
import {
    CakeOutlined,
    MailOutlined,
    PersonOutlined,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import url from "../url"
import { Modal } from "react-bootstrap";
import Request from "../sidebars/friendshiprequests/Request";

export default function Navbar() {
    const [user, setUser] = useState();
    const [birthdays, setBirthdays] = useState([]);
    const [messages, setMessages] = useState([]);
    const [requests, setRequests] = useState([]);
    const [metaData, setMetaData] = useState();
    const [openTwoBirthdaysModal, setTwoOpenBirthdaysModal] = useState(false);
    const [show, setShow] = useState(false);
    var localUser = JSON.parse(localStorage.getItem("user"));

    const navigate = useNavigate();
    useEffect(() => {
        setInterval(() => getUser(localUser.id), 3000);
        getBirthdays();
        getFriendshipRequests();
        getNewMessages();
    }, [localUser.id, navigate]);

    const getUser = (id) => {
        axios.get(`${url}/users/${id}`).then(response => response.data).then(data => setUser(data))
    }

    const getBirthdays = () => {
        axios
            .get(`${url}/birthdays?pageNumber=1&pageSize=48`)
            .then((response) => response.data)
            .then((data) => generateDatas(data));
    };

    const generateDatas = (data) => {
        setBirthdays(data.users);
        setMetaData(data.metaData);
    };

    const goNext = (pageNumber) => {
        axios
            .get(`${url}/birthdays?pageNumber=${pageNumber}&pageSize=32`)
            .then((response) => response.data)
            .then((data) => generateDatas(data));
    };

    const goPrevious = (pageNumber) => {
        axios
            .get(`${url}/birthdays?pageNumber=${pageNumber}&pageSize=32`)
            .then((response) => response.data)
            .then((data) => generateDatas(data));
    };

    const openTwoBirthdaysListModal = () => {
        setTwoOpenBirthdaysModal(true);
    };

    const getFriendshipRequests = () => {
        var currentUser = JSON.parse(localStorage.getItem("user").toString());
        axios
            .get(`${url}/friendships/request?username=${currentUser.username}`)
            .then((response) => response.data)
            .then((data) => setRequests(data));
    };

    const deleteFriendship = (friendshipId) => {
        axios
            .delete(url + "/friendships?friendshipId=" + friendshipId)
            .then((d) => getFriendshipRequests());
    };

    const acceptFriendship = (friendshipId, deviceToken) => {
        var body = [
            {
                path: "friendshipValue",
                op: "replace",
                value: 1,
            },
        ];

        axios
            .patch(url + "/friendships/" + friendshipId, body)
            .then((d) => getFriendshipRequests());

        if (deviceToken !== "yok") {
            axios.post(
                url +
                `/sendacceptednotification?deviceToken=${deviceToken}&username=${localUser.username}`
            );
        }
    };

    const getNewMessages = () => {
        var user = JSON.parse(localStorage.getItem("user"));
        axios.get(`${url}/unreadmessages?username=${user.username}`).then(response => response.data).then(data => setMessages(data));
    }

    return (
        <>
            <nav className="bg-white fixed-top navi px-2 d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-start align-items-center">
                    <label className="brand">Penpal City</label>
                </div>

                <div className="d-flex justify-content-between align-items-center d-sm-flex d-md-none">
                    <Badge badgeContent={requests.length} color="secondary" showZero={false} onClick={() => setShow(true)} className="mx-1 mt-1">
                        <PersonOutlined
                            color="action"
                            style={{ fontWeight: "bold", color: "purple", cursor: "pointer" }}
                        />
                    </Badge>
                    <Badge badgeContent={messages.length} color="secondary" showZero={false} onClick={() => navigate("/messages")} className="mx-1 mt-1">
                        <MailOutlined
                            color="action"
                            style={{ fontWeight: "bold", color: "purple", cursor: "pointer" }}
                        />
                    </Badge>
                    <Badge badgeContent={birthdays.length} color="secondary" showZero={false} onClick={() => openTwoBirthdaysListModal()} className="mx-1">
                        <CakeOutlined
                            color="action"
                            style={{ fontWeight: "bold", color: "purple", cursor: "pointer" }}
                        />
                    </Badge>
                </div>

                <div className="d-flex justify-content-around align-items-center d-none d-sm-none d-md-flex">

                </div>

                <div className="d-flex justify-content-end align-items-center">
                    <div
                        style={{
                            backgroundImage:
                                `url(${user?.profilePicture})`,
                        }}
                        className="rounded-circle navimg d-none d-md-flex"
                    > </div>
                    <div className="position-absolute bottom-0 end-0 me-1 d-flex justify-content-center align-items-center d-sm-flex d-md-none">
                        <label className="nav-item dropdown">
                            <span
                                className="nav-link btn py-1"
                                id="navbarDropdownNav"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <div
                                    style={{
                                        backgroundImage:
                                            `url(${user?.profilePicture})`,
                                    }}
                                    className="rounded-circle navimg"
                                >  </div>
                            </span>
                            <ul
                                className="dropdown-menu px-1"
                                aria-labelledby="navbarDropdownNav"
                            >
                                <li>
                                    <div className="container">
                                        <div className="row my-2 bg-white rounded leftsidebarlistitem">
                                            <div className="col-5 d-flex justify-content-end align-items-center">
                                                <i className="fa-solid fa-house leftsidebarimg"></i>
                                            </div>
                                            <div className="col-7 d-flex justify-content-start align-items-center">
                                                <Link to="/dashboard" className="text-dark">
                                                    <label className="leftsidebarlabel">Dashboard</label>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="container">
                                        <div className="row my-2 bg-white rounded leftsidebarlistitem">
                                            <div className="col-5 d-flex justify-content-end align-items-center">
                                                <i className="fa-solid fa-id-card leftsidebarimg"></i>
                                            </div>
                                            <div className="col-7 d-flex justify-content-start align-items-center">
                                                <Link to="/editprofile" className="text-dark">
                                                    <label className="leftsidebarlabel">
                                                        Edit Profile
                                                    </label>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="container">
                                        <div className="row my-2 bg-white rounded leftsidebarlistitem">
                                            <div className="col-5 d-flex justify-content-end align-items-center">
                                                <i className="fa-solid fa-envelope leftsidebarimg"></i>
                                            </div>
                                            <div className="col-7 d-flex justify-content-start align-items-center">
                                                <Link to="/messages" className="text-dark">
                                                    <label className="leftsidebarlabel">Messages</label>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="container">
                                        <div className="row my-2 bg-white rounded leftsidebarlistitem">
                                            <div className="col-5 d-flex justify-content-end align-items-center">
                                                <i className="fa-solid fa-user-group leftsidebarimg"></i>
                                            </div>
                                            <div className="col-7 d-flex justify-content-start align-items-center">
                                                <Link to="/friends" className="text-dark">
                                                    <label className="leftsidebarlabel">Friends</label>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="container">
                                        <div className="row my-2 bg-white rounded leftsidebarlistitem">
                                            <div className="col-5 d-flex justify-content-end align-items-center">
                                                <i className="fa-solid fa-globe leftsidebarimg"></i>
                                            </div>
                                            <div className="col-7 d-flex justify-content-start align-items-center">
                                                <Link to="/online" className="text-dark">
                                                    <label className="leftsidebarlabel">Online</label>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="container">
                                        <div className="row my-2 bg-white rounded leftsidebarlistitem">
                                            <div className="col-5 d-flex justify-content-end align-items-center">
                                                <i className="fa-solid fa-magnifying-glass leftsidebarimg"></i>
                                            </div>
                                            <div className="col-7 d-flex justify-content-start align-items-center">
                                                <Link to="/detailedsearch" className="text-dark" onClick={() => localStorage.setItem("currentLanguage", "")}>
                                                    <label className="leftsidebarlabel">Search</label>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <div className="container">
                                        <div className="row my-2 bg-white rounded leftsidebarlistitem">
                                            <div className="col-5 d-flex justify-content-end align-items-center">
                                                <i className="fa-solid fa-right-from-bracket leftsidebarimg"></i>
                                            </div>
                                            <div className="col-7 d-flex justify-content-start align-items-center">
                                                <Link to="/" className="text-dark" onClick={() => localStorage.removeItem("user")}>
                                                    <label className="leftsidebarlabel">Log Out</label>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </label>
                    </div>

                </div>
            </nav>
            <Modal
                show={openTwoBirthdaysModal}
                onHide={() => setTwoOpenBirthdaysModal(false)}
            >
                <Modal.Header
                    style={{ fontWeight: "bold", fontSize: "12px", fontStyle: "italic" }}
                >
                    Birthdays
                </Modal.Header>
                <Modal.Body>
                    <div
                        className={`${birthdays.length > 0
                            ? "d-none"
                            : "nobirthdayinfo d-flex flex-column justify-content-center align-items-center"
                            }`}
                    >
                        <i className="fa-solid fa-cake-candles"></i>
                        <label>No Birthdays !</label>
                    </div>
                    <div>
                        {birthdays.map((user) => (
                            <div
                                key={user.id}
                                className="d-flex justify-content-between align-items-center my-1 birthdayitem mx-2"
                            >
                                <img
                                    src={user.profilePicture}
                                    alt=""
                                    title={user.username}
                                />
                                <div className="d-flex flex-column justify-content-center align-items-center px-2">
                                    <label className="birthdaynamelabel">{user.username}</label>
                                    <label className="birthdaycountrylabel">{user.country.name}, {user.age}</label>
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <i className="fa-solid fa-eye birthdayeye" onClick={() => navigate(`/${user.username}/${user.id}`)}></i>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div
                        className={`${metaData?.hasPage === false
                            ? "d-none"
                            : "d-flex justify-content-around align-items-center"
                            }`}
                    >
                        <button
                            className={`${metaData?.hasPrevious === false
                                ? "d-none"
                                : "btn btn-outline-danger btn-sm"
                                }`}
                            style={{ fontSize: "10px" }}
                            onClick={() => goPrevious(metaData.currentPage - 1)}
                        >
                            Prev
                        </button>
                        <button
                            className={`${metaData?.hasPage === false
                                ? "d-none"
                                : "btn btn-outline-success btn-sm"
                                }`}
                            style={{ fontSize: "10px" }}
                            onClick={() => goNext(metaData.currentPage + 1)}
                        >
                            Next
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                className="friendRequestModal"
                show={show}
                onHide={() => setShow(false)}
            >
                <Modal.Body>
                    <div className="modalBodyFriendRequest">
                        <div className="d-flex justify-content-between align-items-center friendshiprequesthead">
                            <label>Friendship Requests</label>
                        </div>
                        <div className={`${requests.length > 0 ? "d-none" : "norequestinfo d-flex flex-column justify-content-center align-items-center"}`}>
                            <i className="fa-solid fa-handshake-slash"></i>
                            <label>No Friendship Requests !</label>
                        </div>
                        {requests.map(request => (
                            <Request key={request.id} user={request} id={localUser.id} acceptFriendship={acceptFriendship} deleteFriendship={deleteFriendship} />
                        ))
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
