import React, { useState, useEffect } from "react";
import Request from "./Request";
import axios from "axios";
import url from "../../url";
import { Modal } from "react-bootstrap";

export default function FriendshipRequest() {
    const [requests, setRequests] = useState([]);
    const [show, setShow] = useState(false);
    var currentUser = JSON.parse(localStorage.getItem("user"));
    useEffect(() => {
        getFriendshipRequests();
    }, []);

    const getFriendshipRequests = () => {
        var currentUser = JSON.parse(localStorage.getItem("user").toString());
        axios
            .get(`${url}/friendships/request?username=${currentUser.username}`)
            .then((response) => response.data)
            .then((data) => setRequests(data));
    };

    const deleteFriendship = (friendshipId) => {
        axios
            .delete(url + "/friendships?friendshipId=" + friendshipId)
            .then((d) => getFriendshipRequests());
    };

    const acceptFriendship = (friendshipId, deviceToken) => {
        var body = [
            {
                path: "friendshipValue",
                op: "replace",
                value: 1,
            },
        ];

        axios
            .patch(url + "/friendships/" + friendshipId, body)
            .then((d) => getFriendshipRequests());

        if (deviceToken !== "yok") {
            axios.post(
                url +
                `/sendacceptednotification?deviceToken=${deviceToken}&username=${currentUser.username}`
            );
        }
    };

    return (
        <>
            <div
                className="w-100 p-1 bg-white rounded px-2"
                style={{ marginTop: "8px" }}
            >
                <div className="d-flex justify-content-between align-items-center friendshiprequest">
                    <label className="requestseeall" onClick={() => setShow(true)}>See All</label>
                    <label>Friendship Requests</label>
                </div>
                <div className={`${requests.length > 0 ? "d-none" : "norequestinfo d-flex flex-column justify-content-center align-items-center"}`}>
                    <i className="fa-solid fa-handshake-slash"></i>
                    <label>No Friendship Requests !</label>
                </div>
                {requests.slice(0, 10).map(user => (
                    <Request key={user.id} user={user} id={currentUser.id} acceptFriendship={acceptFriendship} deleteFriendship={deleteFriendship} />
                ))}
            </div>
            <Modal
                className="friendRequestModal"
                show={show}
                onHide={() => setShow(false)}
            >
                <Modal.Body>
                    <div className="modalBodyFriendRequest">
                        <div className="d-flex justify-content-between align-items-center friendshiprequesthead">
                            <label>Friendship Requests</label>
                        </div>
                        <div className={`${requests.length > 0 ? "d-none" : "norequestinfo d-flex flex-column justify-content-center align-items-center"}`}>
                            <i className="fa-solid fa-handshake-slash"></i>
                            <label>No Friendship Requests !</label>
                        </div>
                        {requests.map(request => (
                            <Request key={request.id} user={request} id={currentUser.id} acceptFriendship={acceptFriendship} deleteFriendship={deleteFriendship} />
                        ))
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
