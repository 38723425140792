import React from 'react'
import User from '../../utilities/user/User'

export default function OnlineMain({ users, metaData, countries, ages, languages, setGender, setSelectedCountry, setSelectedLanguage, setFirstAge, setLastAge, getUsers, goToPrevious, goToNext }) {
    return (
        <div className="w-100 p-1 bg-white rounded px-2 quicksearchsection">
            <div className="d-flex justify-content-between align-items-center quicksearch mb-1">
                <label>Online Users</label>
                <div>
                    <label className="onlinefriendsseeall mx-4">
                        <label className="nav-item dropdown">
                            <span
                                className="nav-link btn py-1"
                                id="navbarDropdown"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Filter
                            </span>
                            <ul
                                className="dropdown-menu px-1"
                                aria-labelledby="navbarDropdown"
                            >
                                <li>
                                    <select
                                        className="form-control my-1"
                                        style={{ fontSize: "10px" }}
                                        onChange={(e) => setGender(e.target.value)}
                                    >
                                        <option value="">Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </li>
                                <li>
                                    <select
                                        className="form-control my-1"
                                        style={{ fontSize: "10px" }}
                                        onChange={(e) => setSelectedCountry(e.target.value)}
                                    >
                                        {countries?.map(country => (
                                            <option key={country.id} value={country.id}>{country.name}</option>
                                        ))}
                                    </select>
                                </li>
                                <li>
                                    <select
                                        className="form-control my-1"
                                        style={{ fontSize: "10px" }}
                                        onChange={(e) => setSelectedLanguage(e.target.value)}
                                    >
                                        {languages?.map(language => (
                                            <option key={language.id} value={language.value}>{language.name}</option>
                                        ))}
                                    </select>
                                </li>
                                <li>
                                    <select
                                        className="form-control my-1"
                                        style={{ fontSize: "10px" }}
                                        onChange={(e) => setFirstAge(e.target.value)}
                                    >
                                        {ages?.map(age => (
                                            <option key={age.id} value={age.value}>{age.name}</option>
                                        ))}
                                    </select>
                                </li>
                                <li>
                                    <select
                                        className="form-control my-1"
                                        style={{ fontSize: "10px" }}
                                        onChange={(e) => setLastAge(e.target.value)}
                                    >
                                        {ages?.map(age => (
                                            <option key={age.id} value={age.value}>{age.name}</option>
                                        ))}
                                    </select>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <button
                                        className="form-control btn btn-outline-primary btn-sm"
                                        style={{ fontSize: "10px" }}
                                        onClick={() => getUsers()}
                                    >
                                        Filter
                                    </button>
                                </li>
                            </ul>
                        </label>
                    </label>
                    <label className="onlinefriendsseeall mx-2">
                        <i className="fa-solid fa-angle-left fw-bold"></i>
                    </label>
                    <label className="onlinefriendsseeall">| {metaData.currentPage} / {metaData.totalPage} |</label>
                    <label className="onlinefriendsseeall mx-2">
                        <i className="fa-solid fa-angle-right fw-bold"></i>
                    </label>
                </div>
            </div>
            <div className={`${users.length > 0 ? "d-none" : "nousersinfo d-flex flex-column justify-content-center align-items-center"}`}>
                <i className="fa-solid fa-users-slash"></i>
                <label>No Online Users Found !</label>
            </div>
            <div className="row px-2">
                {users.map(user => <User key={user.id} user={user} />)}
            </div>
        </div>
    )
}
