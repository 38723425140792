import React, { useState, useEffect } from "react";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import url from "../../utilities/url";
import { Modal } from "react-bootstrap";

export default function Login() {
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [errorMail, setErrorMail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [show, setShow] = useState(false);
  const [forgotmail, setForgotMail] = useState("");
  const [fmailerror, setFMailError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    var user = localStorage.getItem("user");
    if (user !== null) {
      navigate("/dashboard")
    }
  }, [navigate])


  const loginUser = () => {
    if (mail === "") setErrorMail("Please write your mail !");

    if (password === "") setErrorPassword("Please write your password !");

    if (mail !== "" && password !== "") {
      document.getElementById("loginButton")?.setAttribute("disabled", "true");
      setErrorMail("");
      setErrorPassword("");
      axios
        .get(`${url}/loginuser?mail=${mail}&password=${password}`)
        .then((response) =>
          response.data.statusCode === 200
            ? userNavigation(response.data.user)
            : applyError(response.data.error)
        );
    }
  };

  const applyError = (error) => {
    document.getElementById("loginButton")?.removeAttribute("disabled");
    setError(error);
  };

  const userNavigation = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    setError("");
    setSuccess("You have successfully logged in. Please wait !");
    axios
      .get(`${url}/speaklist/${user.id}`)
      .then((response) => response.data)
      .then((data) => {
        if (data.length > 0) {
          setTimeout(() => {
            navigate("/dashboard");
          }, 3000);
        } else {
          setTimeout(() => {
            navigate("/addlanguage");
          }, 3000);
        }
      });
  };

  const sendMail = () => {
    if (forgotmail === "") {
      setFMailError("Please write your mail !");
    } else {
      axios
        .post(`${url}/sendmail?mail=${forgotmail}`)
        .then((response) => response.data)
        .then((data) => checkMailData(data));
    }
  };

  const checkMailData = (data) => {
    if (data === "True") {
      setShow(false);
      setFMailError("");
      setSuccess(
        "Your username and password has been sent. Please check your inbox or spam !"
      );
    } else {
      setSuccess("");
      setFMailError("Invalid mail address ! Please check your mail address !");
    }
  };

  return (
    <>
      <nav className="bg-white fixed-top row navi px-2">
        <div className="col-5 col-sm-5 col-md-4 d-flex justify-content-start align-items-center">
          <label className="brand">Penpal City</label>
        </div>
      </nav>
      <div className="d-flex justify-content-center align-items-center loginpage">
        <div className="container">
          <div className="row">
            <div className="offset-md-3 col-md-6">
              <div className="d-flex flex-column justify-content-center align-items-center px-4 rounded login">
                <div className="rounded-circle">
                  <img
                    className="loginlogo"
                    src="/Images/penpalcity_logo_new.png"
                    alt="Penpal City"
                    title="Penpal City"
                  />
                </div>
                <div
                  className={`erroralert ${success === "" ? "d-none" : "alert alert-success"
                    }`}
                >
                  {success}
                </div>
                <div
                  className={`erroralert ${error === "" ? "d-none" : "alert alert-danger"
                    }`}
                >
                  {error}
                </div>
                <div className="input-group mb-3 inputitem">
                  <span className="input-group-text">Mail</span>
                  <input
                    type="email"
                    className="form-control"
                    name="mail"
                    onChange={(e) => setMail(e.target.value)}
                  />
                </div>
                <div
                  className={`erroralert ${errorMail === "" ? "d-none" : "alert alert-danger"
                    }`}
                >
                  {errorMail}
                </div>
                <div className="input-group mb-3 inputitem">
                  <span className="input-group-text">Password</span>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div
                  className={`erroralert ${errorPassword === "" ? "d-none" : "alert alert-danger"
                    }`}
                >
                  {errorPassword}
                </div>
                <div className="d-flex justify-content-end align-items-center w-100 inputitem">
                  <button
                    id="loginButton"
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => loginUser()}
                  >
                    Login
                  </button>
                </div>
                <hr className="w-100" />
                <div className="d-flex justify-content-end align-items-center w-100 pe-2  inputitem">
                  <label>
                    <Link to="/register">
                      You don't have an account ? Register Now !
                    </Link>
                  </label>
                </div>
                <div className="d-flex justify-content-end align-items-center w-100 pe-2 mt-2 inputitem">
                  <label className="text-primary" onClick={() => setShow(true)}>
                    Forgot Your Password ?
                  </label>
                </div>
                <hr className="w-100" />
                <div className="d-flex justify-content-around align-items-center w-100 pe-2 inputitem">
                  <Link to="/termsofservices">Our Terms of Service</Link>
                  <Link to="/privacypolicy">Our Privacy Policy</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        style={{ marginTop: "100px" }}
      >
        <Modal.Body>
          <div className="loginModal px-2">
            <label>Forgot your password ?</label>
          </div>
          <div
            className={`erroralert mt-2 ${fmailerror === "" ? "d-none" : "alert alert-danger"
              }`}
          >
            {fmailerror}
          </div>
          <div className="d-flex justify-content-between align-items-center px-2 py-2 forgetmailitem">
            <input
              className="form-control"
              placeholder="Please write your mail !"
              name="forgotmail"
              onChange={(e) => setForgotMail(e.target.value)}
            />
            <button
              className="btn btn-outline-primary ms-2"
              onClick={() => sendMail()}
            >
              Send
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
