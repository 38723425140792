import React, { useEffect, useState } from "react";
import Navbar from "../../utilities/navbar/Navbar";
import Footer from "../../utilities/footer/Footer";
import LeftSidebar from "../../utilities/sidebars/LeftSidebar"
import RightSidebar from "../../utilities/sidebars/RightSidebar"
import axios from "axios";
import url from "../../utilities/url";
import { useNavigate } from "react-router-dom";
import "./EditProfile.css"
import EditProfileMain from "./EditProfileMain";
import alertify from "alertifyjs"

export default function EditProfile() {
  const [user, setUser] = useState({});
  const [languages, setLanguages] = useState([]);
  const [levels, setLevels] = useState([]);
  const [mySpeakLanguages, setMySpeakLanguages] = useState([]);
  const [myLearnLanguages, setMyLearnLanguages] = useState([]);
  const [speakLng, setSpeakLng] = useState("");
  const [speakLvl, setSpeakLvl] = useState("");
  const [learnLng, setLearnLng] = useState("");
  const [learnLvl, setLearnLvl] = useState("");
  const [profile, setProfile] = useState("");
  const [background, setBackground] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [birthday, setBirthday] = useState("");
  const [isBirthdayHidden, setIsBirthdayHidden] = useState();
  const [about, setAbout] = useState("");
  const [hobbies, setHobbies] = useState("");
  const [favourites, setFavourites] = useState("");
  const [profileShow, setProfileShow] = useState(false);
  const [backgroundShow, setBackgroundShow] = useState(false);

  var currentUser = JSON.parse(localStorage.getItem("user"));
  var navigate = useNavigate();
  useEffect(() => {
    var localUser = localStorage.getItem("user");
    if (localUser === null) {
      navigate("/");
    } else {
      getMyUserDetails();
      getLanguages();
      getLevels();
    }
  }, [navigate]);

  const getMyUserDetails = () => {
    axios
      .get(url + "/users/" + currentUser.id)
      .then((response) => response.data)
      .then((data) => setCurrentData(data))
  };

  const setCurrentData = (data) => {
    setUser(data);
    setMySpeakLanguages(data.languageKnowns);
    setMyLearnLanguages(data.languageLearnings);
  }

  const getLanguages = () => {
    axios
      .get(url + "/languages")
      .then((response) => response.data)
      .then((data) => setLanguages(data));
  };

  const getLevels = () => {
    axios
      .get(url + "/levels")
      .then((response) => response.data)
      .then((data) => setLevels(data));
  };

  const addNewSpeak = () => {
    if (speakLng === "") {
      alertify.error("Please choose a language !");
    } else if (speakLvl === "") {
      alertify.error("Please choose a level !");
    } else {
      var body = {
        userId: currentUser.id,
        username: currentUser.username,
        language: speakLng,
        level: speakLvl,
      };

      axios
        .post(url + "/speaks", body)
        .then((data) => getMyUserDetails())
        .then((res) => alertify.success(`${speakLng} has been added successfully !`));
      setSpeakLng("");
      setSpeakLvl("");
    }
  };

  const deleteSpeak = (id, language) => {
    axios
      .delete(url + "/speaks/" + id)
      .then((d) => getMyUserDetails())
      .then((s) => alertify.error(`${language} has been deleted successfully !`));
  };

  const addNewLearn = () => {
    if (learnLng === "") {
      alertify.error("Please choose a language !");
    } else if (learnLvl === "") {
      alertify.error("Please choose a level !");
    } else {
      var body = {
        userId: currentUser.id,
        username: currentUser.username,
        language: learnLng,
        level: learnLvl,
      };

      axios
        .post(url + "/learns", body)
        .then((data) => getMyUserDetails())
        .then((res) => alertify.success(`${learnLng} has been added successfully !`));

      setLearnLng("");
      setLearnLvl("");
    }
  };

  const deleteLearn = (id, language) => {
    axios
      .delete(url + "/learns/" + id)
      .then((d) => getMyUserDetails())
      .then((s) => alertify.error(`${language} has been deleted successfully !`));
  };

  const changeProfile = () => {
    var form = new FormData();
    form.append("file", profile);

    axios
      .post(url + `/userphotos/updateprofile?id=${currentUser.id}`, form)
      .then((d) => getMyUserDetails())
      .then(a => alertify.success("Profile Picture has been changed successfully !"));
    setProfileShow(false);
  };

  const changeBackground = (e) => {
    var form = new FormData();
    form.append("file", background);

    axios
      .post(url + `/userphotos/updatebackground?id=${currentUser.id}`, form)
      .then((d) => getMyUserDetails())
      .then(a => alertify.success("Background Picture has been changed successfully !"));
    setBackgroundShow(false);
  };

  const updateInfos = () => {

    //console.log(mail + " " + password + " " + gender + " " + birthday + " " + isBirthdayHidden + " " + countryId + " " + about + " " + hobbies + " " + favourites + " ")

    var data = [
      {
        path: "about",
        op: "replace",
        value: about === "" ? user.about : about,
      },
      {
        path: "hobbies",
        op: "replace",
        value: hobbies === "" ? user.hobbies : hobbies,
      },
      {
        path: "favourites",
        op: "replace",
        value: favourites === "" ? user.favourites : favourites,
      },
      {
        path: "mail",
        op: "replace",
        value: mail === "" ? user.mail : mail,
      },
      {
        path: "password",
        op: "replace",
        value: password === "" ? user.password : password,
      },
      {
        path: "birthday",
        op: "replace",
        value: birthday === "" ? user.birthday : birthday,
      },
      {
        path: "isHiddenBirthday",
        op: "replace",
        value: isBirthdayHidden === undefined ? user.isHiddenBirthday : isBirthdayHidden,
      },
    ];

    axios
      .patch(url + "/users/" + currentUser.id, data)
      .then((d) => getMyUserDetails())
      .then((s) => alertify.success("Changes has been saved successfully !"));
  };

  const deleteUserAccount = () => {
    axios.delete(url + "/users/" + currentUser.id).then(() => localStorage.removeItem("user")).then(() => navigate("/"));
  };

  if (
    !user ||
    !currentUser ||
    !myLearnLanguages ||
    !mySpeakLanguages ||
    !languages ||
    !levels
  )
    return <p>Loading...</p>;

  return (
    <div className="container-fluid" style={{ marginBottom: "60px" }}>
      <Navbar />
      <div className="row" style={{ marginTop: "70px" }}>
        <div className="col-md-3 d-none d-sm-none d-md-block">
          <LeftSidebar />
        </div>
        <div className="col-12 col-md-6">
          <EditProfileMain user={user} updateInfos={updateInfos} setBackgroundShow={setBackgroundShow} setProfileShow={setProfileShow} backgroundShow={backgroundShow} profileShow={profileShow} changeProfile={changeProfile} changeBackground={changeBackground} setProfile={setProfile} setBackground={setBackground} setMail={setMail} setPassword={setPassword} setBirthday={setBirthday} setIsBirthdayHidden={setIsBirthdayHidden} setAbout={setAbout} setHobbies={setHobbies} setFavourites={setFavourites} languages={languages} levels={levels} setSpeakLng={setSpeakLng} setSpeakLvl={setSpeakLvl} setLearnLng={setLearnLng} setLearnLvl={setLearnLvl} addNewSpeak={addNewSpeak} addNewLearn={addNewLearn} deleteSpeak={deleteSpeak} deleteLearn={deleteLearn} deleteUserAccount={deleteUserAccount} />
        </div>
        <div className="col-md-3 d-none d-sm-none d-md-block">
          <RightSidebar />
        </div>
      </div>
      <Footer />
    </div>
  );
}
