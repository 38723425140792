import React from 'react'
import Languages from './langauges/Languages'
import QuickSearch from './quicksearch/QuickSearch'
import SuggestedForYou from './suggestedforyou/SuggestedForYou'
import "./Main.css"

export default function Main({ users, metaData, ages, countries, setGender, setCountry, setFirstAge, setLastAge, goToNext, goToPrevious, getUsers, suggestedUsers }) {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <QuickSearch users={users} metaData={metaData} ages={ages} countries={countries} setGender={setGender} setCountry={setCountry} setFirstAge={setFirstAge} setLastAge={setLastAge} goToNext={goToNext} goToPrevious={goToPrevious} getUsers={getUsers} />
      <Languages />
      <SuggestedForYou suggestedUsers={suggestedUsers} />
    </div>
  )
}
