import React, { useState } from 'react'
import "./Chat.css"
import { useNavigate } from 'react-router-dom'
import { SendOutlined } from '@mui/icons-material';
import { Modal } from 'react-bootstrap';

export default function ChatMain({ user, chats, block, setChatWords, addChat, deleteChat, addBlock, deleteBlock }) {
    const windowHeight = window.innerHeight;
    const divHeight = windowHeight - 208;
    const navigate = useNavigate();
    const [chatId, setChatId] = useState();
    const [show, setShow] = useState(false);
    const [currentWords, setCurrentWords] = useState("")


    const openModal = (id, words) => {
        setChatId(id);
        setCurrentWords(words);
        setShow(true);
    }

    const deleteMessage = () => {
        deleteChat(chatId);
        setCurrentWords("");
        setShow(false)
    }


    return (
        <>
            <div className='chathead bg-white d-flex justify-content-between align-items-center px-2 py-1'>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <label className='chatusername'>{user.username}</label>
                    <label className='chatcountryage'>{user.country?.name},{user.age}</label>
                    <div className='d-flex justify-content-between align-items-center chatuserbuttons'>
                        <button className='btn btn-info btn-sm me-1' onClick={() => navigate(`/${user.username}/${user.id}`)}>See Profile</button>
                        {
                            block.blockedUsername !== user.username ?
                                <button className={`${block.blockingUsername === user.username ? "d-none" : "btn btn-danger btn-sm me-1"}`} onClick={() => addBlock()}>Block {user.username}</button>
                                :
                                <button className={`${block.blockingUsername === user.username ? "d-none" : "btn btn-warning btn-sm me-1"}`} onClick={() => deleteBlock()}>Remove Block</button>
                        }
                    </div>
                </div>
                <div className="col-2 col-md-3 d-flex justify-content-end align-items-center">
                    <div
                        style={{
                            backgroundImage:
                                `url(${user.profilePicture})`,
                        }}
                        className="rounded-circle chatimg"
                    >
                    </div>
                </div>
            </div>
            <div className='chatarea bg-white' style={{ height: divHeight }}>
                {chats?.map(chat => (

                    chat.sender === user.username ?
                        <div className='d-flex justify-content-start align-items-center p-1' key={chat.id}>
                            <div className='rounded py-1 px-2 text-white chatareatext' style={{ backgroundColor: "#DDA0DD" }}>{chat.words}</div>
                        </div>
                        :
                        <div className='d-flex justify-content-end align-items-center p-1' key={chat.id}>
                            <div className='rounded py-1 px-2 text-white chatareatext' style={{ backgroundColor: "#800080", cursor: "pointer" }} onClick={() => openModal(chat.id, chat.words)}>{chat.words}</div>
                        </div>
                ))}
            </div>
            {block.blockingUsername === user.username ?
                <div className='d-flex justify-content-center align-items-center' style={{ height: "33px", backgroundColor: "#dda0dd" }}>
                    <p className='text-danger mt-1' style={{ fontSize: "9px" }}>You are not allowed to contact {user.username}</p>
                </div>
                : block.blockedUsername === user.username ?
                    <div className='d-flex justify-content-center align-items-center' style={{ height: "33px", backgroundColor: "#dda0dd" }}>
                        <p className='text-danger mt-1' style={{ fontSize: "9px" }}>You blocked {user.username} ! Remove block to contact {user.username}</p>
                    </div>
                    :
                    <div className='chatmessage bg-white d-flex justify-content-between align-items-center' style={{ top: divHeight + 125, backgroundColor: "#dda0dd" }}>
                        <input id='chatWords' className='form-control words' name='chatWords' onChange={(e) => setChatWords(e.target.value)} style={{ fontSize: "12px" }} />
                        <button className='btn btn-outline-primary btn-sm' onClick={() => addChat()}><SendOutlined /></button>
                    </div>
            }
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Body>
                    <div className='d-flex justify-content-center align-items-center text-small' style={{ fontWeight: "bold", fontSize: "14px" }}>Are you sure to delete this message ?</div>
                    <div className='d-flex justify-content-center align-items-center text-small' style={{ fontSize: "12px", fontStyle: "italic" }}>
                        "{currentWords}"
                    </div>
                    <hr />
                    <div className='d-flex justify-content-around align-items-center'>
                        <button className='btn btn-outline-secondary btn-sm' style={{ fontSize: "11px" }} onClick={() => setShow(false)}>Cancel</button>
                        <button className='btn btn-outline-danger btn-sm' style={{ fontSize: "11px" }} onClick={() => deleteMessage()}>Delete</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
