import React, { useEffect, useState } from "react";
import NewMessage from "./NewMessage";
import axios from "axios";
import url from "../../url";
import { Link } from "react-router-dom";

export default function NewMessages() {

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    getNewMessages();
  }, [])

  const getNewMessages = () => {
    var user = JSON.parse(localStorage.getItem("user"));
    axios.get(`${url}/unreadmessages?username=${user.username}`).then(response => response.data).then(data => setMessages(data));
  }
  return (
    <div className="w-100 p-1 bg-white rounded px-2 newmessagesdiv">
      <div className="d-flex justify-content-between align-items-center newmessages mb-1">
        <Link to="/messages"><label className="newmessagesseeall">See All</label></Link>
        <label>New Messages</label>
      </div>
      <div className={`${messages.length > 0 ? "d-none" : "nonewmessagesinfo d-flex flex-column justify-content-center align-items-center"}`}>
        <i className="fa-solid fa-comment-slash"></i>
        <label>No New Messages !</label>
      </div>
      {messages.slice(0, 10).map(message => (
        <NewMessage key={message.id} message={message} />
      ))}
    </div>
  );
};