import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import url from "../../utilities/url";
import { ErrorOutlineOutlined } from "@mui/icons-material";

export default function Friend({ friend, deleteFriendship, id }) {
  const [friendship, setFriendship] = useState({});

  useEffect(() => {
    getOneFriendship();
  }, []);

  const getOneFriendship = () => {
    axios
      .get(url + `/friendships/onefriend?id=${id}&username=${friend.username}`)
      .then((response) => response.data)
      .then((data) => setFriendship(data));
  };

  return (
    <div
      className="px-2 col-3 col-md-3 mb-2"
      title={friend.about}
    >
      <div className="border border-solid border-1 rounded">
        <div className="d-flex justify-content-end align-items-center">
          <span className={`onlinestatus ${friend.login.isOnline === false ? "bg-danger" : "bg-success"} me-2 mt-1`}></span>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <img
            src={friend.profilePicture}
            alt=""
            title=""
            className="rounded-circle userimg"
          />
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <label className={`usernamelabel ${friend.gender === "Male" ? "text-primary" : "text-danger"}`}>{friend.username}</label>
        </div>
        <div className="d-flex justify-content-around align-items-center mx-2 my-1">
          <label className="userage">{friend.age}</label>
          <img
            src={friend.country.countryFlag}
            alt=""
            title={friend.country.name}
            className="usercountryflag"
          />
          <label className="nav-item dropdown">
            <span
              className="nav-link btn py-1"
              id="navbarDropdownNav"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <ErrorOutlineOutlined
                style={{ color: "purple", cursor: "pointer", fontSize: "18px" }}
              />
            </span>
            <ul
              className="dropdown-menu px-1"
              aria-labelledby="navbarDropdownNav"
            >
              <li>
                <div className="container">
                  <div className="d-flex justify-content-between align-items-center">
                    <i className="fa-solid fa-eye friendsoptions"></i>
                    <Link to={`/${friend.username}/${friend.id}`} className="text-dark">
                      <label className="friendsoptionslabel">See Profile</label>
                    </Link>
                  </div>
                </div>
              </li>

              <li>
                <div className="container">
                  <div className="d-flex justify-content-between align-items-center">
                    <i className="fa-solid fa-envelope friendsoptions"></i>
                    <Link to={`/chat/${friend.username}/${friend.id}`} className="text-dark">
                      <label className="friendsoptionslabel">Send Message</label>
                    </Link>
                  </div>
                </div>
              </li>

              <li>
                <div className="container">
                  <div className="d-flex justify-content-between align-items-center">
                    <i className="fa-solid fa-trash friendsoptions"></i>
                    <label className="friendsoptionslabel" onClick={() => deleteFriendship(friendship.friendshipId)}>
                      Delete Friendship
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </label>
        </div>
      </div>
    </div>
  );
}
