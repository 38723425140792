import React, { useEffect, useState } from "react";
import Navbar from "../../utilities/navbar/Navbar";
import Footer from "../../utilities/footer/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import url from "../../utilities/url";
import "./Messages.css"
import LeftSidebar from "../../utilities/sidebars/LeftSidebar"
import RightSidebar from "../../utilities/sidebars/RightSidebar"
import MessagesMain from "./MessagesMain";


export default function MyMessages() {
  const [messages, setMessages] = useState([]);
  var currentUser = JSON.parse(localStorage.getItem("user"));
  var navigate = useNavigate();
  useEffect(() => {
    var localUser = localStorage.getItem("user");
    if (localUser === null) {
      navigate("/");
    } else {
      getMyMessages();
    }
  }, [navigate]);

  const getMyMessages = () => {
    axios
      .get(url + "/messageprofiles?username=" + currentUser.username)
      .then((response) => response.data)
      .then((data) => setMessages(data));
  };

  const openMessage = (username, id) => {
    markAsRead(username);
    navigate(`/chat/${username}/${id}`);
  }

  const markAsRead = (username) => {
    var data = [
      {
        path: "messageStatus",
        op: "replace",
        value: "True",
      },
    ];

    axios.patch(
      url +
      `/messageprofiles?currentusername=${currentUser.username}&username=${username}`,
      data
    ).then(d => getMyMessages());
  };

  const markAsUnread = (username) => {
    var data = [
      {
        path: "messageStatus",
        op: "replace",
        value: "False",
      },
    ];

    axios.patch(
      url +
      `/messageprofiles?currentusername=${currentUser.username}&username=${username}`,
      data
    ).then(d => getMyMessages());
  };

  const deleteMessage = (username) => {
    axios
      .delete(
        url +
        `/messageprofiles?currentusername=${currentUser.username}&username=${username}`
      )
      .then((d) => getMyMessages());
  };

  if (!currentUser || !messages) return <p>Loading...</p>;

  return (
    <div className="container-fluid" style={{ marginBottom: "60px" }}>
      <Navbar />
      <div className="row" style={{ marginTop: "70px" }}>
        <div className="col-md-3 d-none d-sm-none d-md-block">
          <LeftSidebar />
        </div>
        <div className="col-12 col-md-6">
          <MessagesMain messages={messages} markAsRead={markAsRead} markAsUnread={markAsUnread} deleteMessage={deleteMessage} openMessage={openMessage}/>
        </div>
        <div className="col-md-3 d-none d-sm-none d-md-block">
          <RightSidebar />
        </div>
      </div>
      <Footer />
    </div>
  );
}
