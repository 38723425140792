import React from 'react'
import Message from './Message'

export default function MessagesMain({ messages, markAsRead, markAsUnread, deleteMessage, openMessage }) {
    return (
        <div className="w-100 p-1 bg-white rounded px-2 messagessection">
            <div className="d-flex justify-content-between align-items-center messages mb-2">
                <label>Messages</label>
            </div>
            <div className={`${messages.length > 0 ? "d-none" : "nonewmessagesinfo d-flex flex-column justify-content-center align-items-center"}`}>
                <i className="fa-solid fa-comment-slash"></i>
                <label>No Messages !</label>
            </div>
            {messages.map(message => (
                <Message message={message} key={message.id} markAsRead={markAsRead} markAsUnread={markAsUnread} deleteMessage={deleteMessage} openMessage={openMessage}/>
            ))}

        </div>
    )
}
