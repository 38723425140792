import React, { useEffect, useState } from "react";
import Birthday from "./Birthday";
import url from "../../url";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function Birthdays() {
  const [birthdays, setBirthdays] = useState([]);
  const [shortBirthdays, setShortBirthdays] = useState([]);
  const [openBirthdaysModal, setOpenBirthdaysModal] = useState(false);
  const [metaData, setMetaData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getShortBirthdays();
    getBirthdays();
  }, [navigate]);

  const getShortBirthdays = () => {
    axios
      .get(`${url}/birthdays?pageNumber=1&pageSize=48`)
      .then((response) => response.data.users)
      .then((data) => setShortBirthdays(data));
  };

  const getBirthdays = () => {
    axios
      .get(`${url}/birthdays?pageNumber=1&pageSize=48`)
      .then((response) => response.data)
      .then((data) => generateDatas(data));
  };

  const generateDatas = (data) => {
    setBirthdays(data.users);
    setMetaData(data.metaData);
  };

  const goNext = (pageNumber) => {
    axios
      .get(`${url}/birthdays?pageNumber=${pageNumber}&pageSize=32`)
      .then((response) => response.data)
      .then((data) => generateDatas(data));
  };

  const goPrevious = (pageNumber) => {
    axios
      .get(`${url}/birthdays?pageNumber=${pageNumber}&pageSize=32`)
      .then((response) => response.data)
      .then((data) => generateDatas(data));
  };

  const openBirthdaysListModal = () => {
    setOpenBirthdaysModal(true);
  };

  return (
    <>
      <div className="w-100 p-1 bg-white rounded px-2 birthdays">
        <div className="d-flex justify-content-between align-items-center birthday">
          <label
            className="birthdayseeall"
            onClick={() => openBirthdaysListModal()}
          >
            See All
          </label>
          <label>Birthdays</label>
        </div>
        <div
          className={`${shortBirthdays.length > 0
            ? "d-none"
            : "nobirthdayinfo d-flex flex-column justify-content-center align-items-center"
            }`}
        >
          <i className="fa-solid fa-cake-candles"></i>
          <label>No Birthdays !</label>
        </div>
        {shortBirthdays.slice(0, 6).map((user) => (
          <Birthday key={user.id} user={user} />
        ))}
      </div>
      <Modal
        show={openBirthdaysModal}
        onHide={() => setOpenBirthdaysModal(false)}
      >
        <Modal.Header
          style={{ fontWeight: "bold", fontSize: "12px", fontStyle: "italic" }}
        >
          Birthdays
        </Modal.Header>
        <Modal.Body>
          <div
            className={`${birthdays.length > 0
              ? "d-none"
              : "nobirthdayinfo d-flex flex-column justify-content-center align-items-center"
              }`}
          >
            <i className="fa-solid fa-cake-candles"></i>
            <label>No Birthdays !</label>
          </div>
          <div>
            {birthdays.map((user) => (
              <div
                key={user.id}
                className="d-flex justify-content-between align-items-center my-1 birthdayitem mx-2"
              >
                <img
                  src={user.profilePicture}
                  alt=""
                  title={user.username}
                />
                <div className="d-flex flex-column justify-content-center align-items-center px-2">
                  <label className="birthdaynamelabel">{user.username}</label>
                  <label className="birthdaycountrylabel">{user.country.name}, {user.age}</label>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <i className="fa-solid fa-eye birthdayeye" onClick={() => navigate(`/${user.username}/${user.id}`)}></i>
                </div>
              </div>
            ))}
          </div>
          <div
            className={`${metaData?.hasPage === false
              ? "d-none"
              : "d-flex justify-content-around align-items-center"
              }`}
          >
            <button
              className={`${metaData?.hasPrevious === false
                ? "d-none"
                : "btn btn-outline-danger btn-sm"
                }`}
              style={{ fontSize: "10px" }}
              onClick={() => goPrevious(metaData.currentPage - 1)}
            >
              Prev
            </button>
            <button
              className={`${metaData?.hasPage === false
                ? "d-none"
                : "btn btn-outline-success btn-sm"
                }`}
              style={{ fontSize: "10px" }}
              onClick={() => goNext(metaData.currentPage + 1)}
            >
              Next
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

