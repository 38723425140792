import React from "react";
import { Link } from "react-router-dom";

export default function Birthday({ user }) {
    return (
        <>
            <div className="d-flex justify-content-between align-items-center my-1 birthdayitem mx-2">
                <img
                    src={user.profilePicture}
                    alt="Profile Resmi"
                    title="Profile Resmi"
                />
                <div className="d-flex flex-column justify-content-center align-items-center px-2">
                    <label className="birthdaynamelabel">{user.username}</label>
                    <label className="birthdaycountrylabel">{user.country.name}, {user.age}</label>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <Link to={`/${user.username}/${user.id}`}>
                        <i className="fa-solid fa-eye birthdayeye"></i>
                    </Link>
                </div>
            </div>
            <hr />
        </>
    );
};
