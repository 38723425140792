import React, { useEffect, useState } from "react";
import Navbar from "../../utilities/navbar/Navbar";
import Footer from "../../utilities/footer/Footer";
import axios from "axios";
import url from "../../utilities/url";
import { useNavigate } from "react-router-dom";
import LeftSidebar from "../../utilities/sidebars/LeftSidebar"
import RightSidebar from "../../utilities/sidebars/RightSidebar"
import FriendsMain from "./FriendsMain";
import "./Friends.css"


export default function Friends() {
  const [friends, setFriends] = useState([]);
  const [requests, setRequests] = useState([]);
  var currentUser = JSON.parse(localStorage.getItem("user"));
  var navigate = useNavigate();
  useEffect(() => {
    var localUser = localStorage.getItem("user");
    if (localUser === null) {
      navigate("/");
    } else {
      getFriends();
      getRequests();
    }
  }, []);

  const getFriends = () => {
    axios
      .get(url + `/friendships?userId=${currentUser.id}`)
      .then((response) => response.data)
      .then((data) => setFriends(data));
  };

  const getRequests = () => {
    axios
      .get(url + `/friendships/request?username=${currentUser.username}`)
      .then((response) => response.data)
      .then((data) => setRequests(data));
  };

  const deleteFriendship = (friendshipId) => {
    axios
      .delete(url + "/friendships?friendshipId=" + friendshipId)
      .then((data) => getFriends())
      .then((d) => getRequests());
  };

  const acceptFriendship = (friendshipId, deviceToken) => {
    var body = [
      {
        path: "friendshipValue",
        op: "replace",
        value: 1,
      },
    ];

    axios
      .patch(url + "/friendships/" + friendshipId, body)
      .then((data) => getFriends())
      .then((d) => getRequests());

    if (deviceToken !== "yok") {
      axios.post(
        url +
        `/sendacceptednotification?deviceToken=${deviceToken}&username=${currentUser.username}`
      );
    }
  };

  return (
    <div className="container-fluid" style={{ marginBottom: "60px" }}>
      <Navbar />
      <div className="row" style={{ marginTop: "70px" }}>
        <div className="col-md-3 d-none d-sm-none d-md-block">
          <LeftSidebar />
        </div>
        <div className="col-12 col-md-6">
          <FriendsMain friends={friends} id={currentUser.id} requests={requests} acceptFriendship={acceptFriendship} deleteFriendship={deleteFriendship} />
        </div>
        <div className="col-md-3 d-none d-sm-none d-md-block">
          <RightSidebar />
        </div>
      </div>
      <Footer />
    </div>
  );
}
