import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Message({ message, markAsRead, markAsUnread, deleteMessage, openMessage }) {
  const [show, setShow] = useState(false);
  return (
    <>
      <div
        className="messageitem px-2 d-flex align-items-center"
        title={message.words}
      >
        <img
          src={message.user.profilePicture}
          alt=""
        />
        <div className="w-100 d-flex flex-column justify-content-start align-items-center">
          <div className="messagesender w-100 d-flex justify-content-between align-items-center px-3">
            <label className={`${message.user.gender === "Male" ? "text-primary" : "text-danger"}`}>{message.user.username}</label>
            <label className="nav-item dropdown">
              <span
                className="nav-link btn py-1"
                id="navbarDropdownNav"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa-solid fa-ellipsis"></i>
              </span>
              <ul
                className="dropdown-menu px-1"
                aria-labelledby="navbarDropdownNav"
              >
                <li>
                  <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                      <i className="fa-solid fa-envelope-open messageoptions"></i>
                      <label className="messageoptionslabel" onClick={() => openMessage(message.user.username, message.user.id)}>
                        Open Message
                      </label>
                    </div>
                  </div>
                </li>
                {message.messageStatus === "False" ? (
                  <li>
                    <div className="container">
                      <div className="d-flex justify-content-between align-items-center">
                        <i className="fa-solid fa-envelope-circle-check messageoptions"></i>
                        <label className="messageoptionslabel" onClick={() => markAsRead(message.user.username)}>
                          Mark As Read
                        </label>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li>
                    <div className="container">
                      <div className="d-flex justify-content-between align-items-center">
                        <i className="fa-solid fa-square-envelope messageoptions"></i>
                        <label className="messageoptionslabel" onClick={() => markAsUnread(message.user.username)}>
                          Mark As Unread
                        </label>
                      </div>
                    </div>
                  </li>
                )}

                <li>
                  <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                      <i className="fa-solid fa-trash messageoptions"></i>
                      <label
                        className="messageoptionslabel"
                        onClick={() => setShow(true)}
                      >
                        Delete Message
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            </label>
          </div>
          <div
            className={`messagetext ${message.messageStatus === "False" ? "text-danger" : "text-secondary"
              } ps-3 w-100`}
          >
            {message.words}
          </div>
        </div>
      </div>
      <hr />
      <Modal
        className="deletionModal"
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Body>
          <div className="modalBody">
            <p>You want to delete message from {message.user.username} ?</p>
            <hr />
            <div className="d-flex justify-content-between align-items-center">
              <button
                className="btn btn-outline-secondary btn-sm"
                onClick={() => setShow(false)}
              >
                Cancel
              </button>
              <button className="btn btn-outline-danger btn-sm" onClick={() => deleteMessage(message.user.username)}>Delete</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
